<template>
	<div class="result" :class="{'red': isHibiscus, 'green': isGreenTea, 'dark-red': isBlackTea, 'orange': isOrange, 'yellow': isGinger, 'light-red': isGrapefruit}">
		<Header v-if="!isGinger" />
		<HeaderColor v-if="isGinger" />
    <div class="result__content">
      <div class="result__content-wrapper" v-if="isHibiscus">
        <div class="result__left" data-aos="fade-right" data-aos-delay="100">
          <h1>Настроение <br class="mob-only">для <br>свидания <br class="mob-only">с собой</h1>
          <div class="result__product" data-aos="zoom-in" data-aos-delay="500">
            <img src="@/assets/img/lemonade-4.png" alt="">
          </div> <!--МЕСТО ДЛЯ БУТЫЛКИ В МОБИЛЬНОЙ ВЕРСИИ-->
          <h2>Тебя ждут: тишина, <br>спокойствие и <br>гармония <br class="mob-only">с собой</h2>
          <div class="result__text">
            <p>Иногда хочется взять паузу и побыть наедине <br class="mob-only">с собой. <br>Для тебя идеальное лето — такое, когда <br>есть возможность уделить время себе: например, <br>сходить на прогулку, устроиться на мягком пледе <br class="mob-only"><br>в парковой тени и зачитаться любимой книгой.</p>
            <p>Охладиться даже в самый жаркий полдень поможет Чай <br>каркаде с земляникой. Сладкая <br class="mob-only">и ароматная земляника <br>идеально сочетается <br class="mob-only">с бархатистой кислинкой чая <br>каркаде и придает вкусу особое очарование.</p>
          </div>
        </div>
        <div class="result__product result__product--desk" data-aos="zoom-in" data-aos-delay="500">
          <img src="@/assets/img/lemonade-4.png" alt="">
        </div>
        <div class="result__right" data-aos="fade-left" data-aos-delay="100">
          <h3>Твоя подборка <br>идей от Афиши <br>для активного досуга:</h3>
          <div class="result__right-content">
            <a :href="mskEventHibicus.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="mskEventHibicus.image" alt=""></div>
              <div class="result__note">{{mskEventHibicus.name}}</div>
            </a>
            <a :href="spbEventHibicus.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="spbEventHibicus.image" alt=""></div>
              <div class="result__note">{{spbEventHibicus.name}}</div>
            </a>
            <a :href="sochiEventHibicus.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="sochiEventHibicus.image" alt=""></div>
              <div class="result__note">{{sochiEventHibicus.name}}</div>
            </a>
          </div>
        </div>
      </div>
      <div class="result__content-wrapper" v-if="isGreenTea">
        <div class="result__left" data-aos="fade-right" data-aos-delay="100">
          <h1>Настроение <br class="mob-only">для <br>новых свершений</h1>
          <div class="result__product" data-aos="zoom-in" data-aos-delay="500">
            <img src="@/assets/img/lemonade-3.png" alt="">
          </div>
          <h2>Тебя ждут: <br class="mob-only">новые <br>увлечения <br class="mob-only">и <br>обновление</h2>
          <div class="result__text">
            <p>Для тебя лето — время, когда силы и вдохновение бьют <br>через край. Хочется попробовать лепку <br class="mob-only">на гончарном <br>круге, встать на скейт или сёрф-доску, освоить новую <br>профессию и записаться <br class="mob-only">на тысячу и один <br>увлекательный курс. А лучше <br class="mob-only">всё и сразу.</p>
            <p>Поддерживать энергию поможет полезный холодный <br>напиток. Зелёный чай придаст бодрости, а клубника <br>и базилик добавляют изысканности вкуса и витамины, <br>без которых <br class="mob-only">не обойтись даже в солнечную погоду.</p>
          </div>
        </div>
        <div class="result__product result__product--desk" data-aos="zoom-in" data-aos-delay="500">
          <img src="@/assets/img/lemonade-3.png" alt="">
        </div>
        <div class="result__right" data-aos="fade-left" data-aos-delay="100">
          <h3>Твоя подборка <br>идей от Афиши <br>для активного досуга:</h3>
          <div class="result__right-content">
            <a :href="mskEventGreenTea.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="mskEventGreenTea.image" alt=""></div>
              <div class="result__note">{{mskEventGreenTea.name}}</div>
            </a>
            <a :href="spbEventGreenTea.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="spbEventGreenTea.image" alt=""></div>
              <div class="result__note">{{spbEventGreenTea.name}}</div>
            </a>
            <a :href="sochiEventGreenTea.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="sochiEventGreenTea.image" alt=""></div>
              <div class="result__note">{{sochiEventGreenTea.name}}</div>
            </a>
          </div>
        </div>
      </div>
      <div class="result__content-wrapper" v-if="isBlackTea">
        <div class="result__left" data-aos="fade-right" data-aos-delay="100">
          <h1>Настроение <br class="mob-only">для <br>вечера <br class="mob-only">в деревне</h1>
          <div class="result__product" data-aos="zoom-in" data-aos-delay="500">
            <img src="@/assets/img/lemonade-5.png" alt="">
          </div>
          <h2>Тебя ждут: уютный <br class="mob-only">плед <br>и звёздное <br class="mob-only">небо</h2>
          <div class="result__text">
            <p>Ваше идеальное лето — у бабушки в деревне. Даже если <br>вы давно живёте в городе, летом нет-нет да и хочется <br>сбежать от шума и суеты <br class="mob-only">в уютный домик, выходящий <br>окнами в сад. <br class="mob-only">Там можно нарвать ароматной малины <br>и заварить вкуснейший чай. В жару он поможет охладиться, <br class="mob-only"><br>а прохладным вечером — согреться, любуясь звёздами.</p>
            <p>Но даже, когда обстоятельства не позволяют надолго <br>уехать за город, перенестись в приятные воспоминания <br>можно, попробовав Чёрный чай со вкусом малины от <br>«Мираторга». Или посетив мероприятия из подборки <br>«Афиши».</p>
          </div>
        </div>
        <div class="result__product result__product--desk" data-aos="zoom-in" data-aos-delay="500">
          <img src="@/assets/img/lemonade-5.png" alt="">
        </div>
        <div class="result__right" data-aos="fade-left" data-aos-delay="100">
          <h3>Твоя подборка <br>идей от Афиши <br>для активного досуга:</h3>
          <div class="result__right-content">
            <a :href="mskEventBlackTea.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="mskEventBlackTea.image" alt=""></div>
              <div class="result__note">{{mskEventBlackTea.name}}</div>
            </a>
            <a :href="spbEventBlackTea.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="spbEventBlackTea.image" alt=""></div>
              <div class="result__note">{{spbEventBlackTea.name}}</div>
            </a>
            <a :href="sochiEventBlackTea.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="sochiEventBlackTea.image" alt=""></div>
              <div class="result__note">{{sochiEventBlackTea.name}}</div>
            </a>
          </div>
        </div>
      </div>
      <div class="result__content-wrapper" v-if="isOrange">
        <div class="result__left" data-aos="fade-right" data-aos-delay="100">
          <h1>Настроение <br class="mob-only">для <br>ветра <br class="mob-only">в волосах</h1>
          <div class="result__product" data-aos="zoom-in" data-aos-delay="500">
            <img src="@/assets/img/lemonade-6.png" alt="">
          </div>
          <h2>Тебя ждут: <br>умопомрачительные <br>рассветы и закаты на берегу</h2>
          <div class="result__text">
            <p>Что может быть лучше, чем море, солнце, лёгкий бриз и целый <br>отпуск впереди? Просыпаться <br class="mob-only">с рассветом, вдыхать солёный воздух, наслаждаться любимыми фруктами и купаться дни напролёт: <br>в солнечных лучах или морских волнах — решать тебе. А затем <br>засыпать <br class="mob-only">под шум прибоя с обгоревшим носом <br class="mob-only">и выгоревшими <br>прядками волос. Кажется, <br class="mob-only">это и есть твоё идеальное лето.</p>
            <p>Вишенкой на торте , — а точнее зонтиком на высоком запотевшем от <br>льда стакане — станет Лимонад со вкусом апельсина и лемонграсса. <br>Но даже если отправиться к морю прямо сейчас не получается, <br>окунуться в пляжную атмосферу, не уезжая из города, поможет <br>подборка мероприятий от «Афиши».</p>
          </div>
        </div>
        <div class="result__product result__product--desk" data-aos="zoom-in" data-aos-delay="500">
          <img src="@/assets/img/lemonade-6.png" alt="">
        </div>
        <div class="result__right" data-aos="fade-left" data-aos-delay="100">
          <h3>Твоя подборка <br>идей от Афиши <br>для активного досуга:</h3>
          <div class="result__right-content">
            <a :href="mskEventOrange.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="mskEventOrange.image" alt=""></div>
              <div class="result__note">{{mskEventOrange.name}}</div>
            </a>
            <a :href="spbEventOrange.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="spbEventOrange.image" alt=""></div>
              <div class="result__note">{{spbEventOrange.name}}</div>
            </a>
            <a :href="sochiEventOrange.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="sochiEventOrange.image" alt=""></div>
              <div class="result__note">{{sochiEventOrange.name}}</div>
            </a>
          </div>
        </div>
      </div>
      <div class="result__content-wrapper" v-if="isGinger">
        <div class="result__left" data-aos="fade-right" data-aos-delay="100">
          <h1>Настроение <br class="mob-only">для <br>приключений</h1>
          <div class="result__product" data-aos="zoom-in" data-aos-delay="500">
            <img src="@/assets/img/lemonade-1.png" alt="">
          </div>
          <h2>Тебя ждут: горы <br class="mob-only">по <br>плечо и море <br class="mob-only">по колено</h2>
          <div class="result__text">
            <p>Иногда хочется простого человеческого — отбросить рутинные дела <br>и отправиться на край света. Покорять крутые горные склоны, <br>сплавляться по бурным рекам, а может пробираться к пирамидам <br>через песчаные пустыни? Или исследовать загадочный <br class="mob-only"><br>лес по соседству, отключив телефон в жаркий субботний полдень?</p>
            <p>В любом случае, Лимонад с имбирём поможет освежиться, <br>суперполезные витамины <br class="mob-only">и минералы придадут сил для очередного <br>приключения, а удобная и герметичная бутылочка поместится <br>в любой рюкзак или сумку. Кстати, чтобы отправиться в поход, <br>не обязательно уезжать далеко за город. Где окунуться <br class="mob-only"><br>в приключения, рассказываем в подборке <br class="mob-only">от «Афиши».</p>
          </div>
        </div>
        <div class="result__product result__product--desk" data-aos="zoom-in" data-aos-delay="500">
          <img src="@/assets/img/lemonade-1.png" alt="">
        </div>
        <div class="result__right" data-aos="fade-left" data-aos-delay="100">
          <h3>Твоя подборка <br>идей от Афиши <br>для активного досуга:</h3>
          <div class="result__right-content">
            <a :href="mskEventGinger.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="mskEventGinger.image" alt=""></div>
              <div class="result__note">{{mskEventGinger.name}}</div>
            </a>
            <a :href="spbEventGinger.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="spbEventGinger.image" alt=""></div>
              <div class="result__note">{{spbEventGinger.name}}</div>
            </a>
            <a :href="sochiEventGinger.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="sochiEventGinger.image" alt=""></div>
              <div class="result__note">{{sochiEventGinger.name}}</div>
            </a>
          </div>
        </div>
      </div>
      <div class="result__content-wrapper" v-if="isGrapefruit">
        <div class="result__left" data-aos="fade-right" data-aos-delay="100">
          <h1>Настроение <br class="mob-only">для <br>дружеских вечеров</h1>
          <div class="result__product" data-aos="fade-in">
            <img src="@/assets/img/lemonade-2.png" alt="">
          </div> <!--МЕСТО ДЛЯ БУТЫЛКИ В МОБИЛЬНОЙ ВЕРСИИ-->
          <h2>Тебя ждут: разговоры <br class="mob-only"><br>до утра и песни под <br class="mob-only"><br>гитару</h2>
          <div class="result__text">
            <p>Твоё идеальное лето — то, которое можно провести с друзьями. Всем <br>вместе отправиться покорять вечерний город? Отлично! Провести <br>ламповый вечер на балконе с настолками <br class="mob-only">и песнями? Ещё лучше!</p>
            <p>А скрасить любые посиделки поможет освежающий Лимонад <br>из грейпфрута. Обладая ярким и сочным вкусом, он будет отличным <br>дополнением и к праздничному ужину, <br class="mob-only">и к любимым закускам <br>под кино. <br class="mob-only">А ещё на его основе можно делать безалкогольные <br>коктейли — посоревнуйтесь <br class="mob-only">с друзьями за звание лучшего бармена!</p>
          </div>
        </div>
        <div class="result__product result__product--desk" data-aos="zoom-in" data-aos-delay="500">
          <img src="@/assets/img/lemonade-2.png" alt="">
        </div>
        <div class="result__right" data-aos="fade-left" data-aos-delay="100">
          <h3>Твоя подборка <br>идей от Афиши <br>для активного досуга:</h3>
          <div class="result__right-content">
            <a :href="mskEventGrapefruit.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="mskEventGrapefruit.image" alt=""></div>
              <div class="result__note">{{mskEventGrapefruit.name}}</div>
            </a>
            <a :href="spbEventGrapefruit.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="spbEventGrapefruit.image" alt=""></div>
              <div class="result__note">{{spbEventGrapefruit.name}}</div>
            </a>
            <a :href="sochiEventGrapefruit.link" target="_blank" class="result__item" @click="sendEvent('click_afishaEvent')">
              <div class="result__image"><img :src="sochiEventGrapefruit.image" alt=""></div>
              <div class="result__note">{{sochiEventGrapefruit.name}}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="result__links">
      <a :href="link" target="_blank" class="result__link" @click="buy">Купить</a>
      <router-link to="/products" class="result__link"><span @click="sendEvent('click_allTaste')">попробуй все вкусы</span></router-link>
    </div>
    <Footer />
	</div>
</template>

<script>
	import AOS from 'aos';
  import 'aos/dist/aos.css';
	import Header from '@/components/Header'
	import HeaderColor from '@/components/HeaderColor'
	import Footer from '@/components/Footer'
  export default {
		name: "Result",
    props: {
      active: {
        type: Number,
        required: true,
      }
    },
    data() {
      return {
        link: 'https://eda.yandex.ru/search?query=лимонад%20мираторг&type=all',
        isHibiscus: false,
        isGreenTea: false,
        isBlackTea: false,
        isOrange: false,
        isGinger: false,
        isGrapefruit: false,
        events: {
          hibiscus: [
            {
              id: 1,
              name: 'Увидеть славянскую легенду',
              link: 'https://www.afisha.ru/performance/zhizel-204665/',
              city: 1,
              image: 'events/hibiscus/moscow-1.jpg'
            },
            {
              id: 2,
              name: 'Согреться музыкой',
              link: 'https://www.afisha.ru/concert/v-svechah-shedevry-klassiki-v-otele-nacional-2210627/',
              city: 1,
              image: 'events/hibiscus/moscow-4.jpg'
            },
            {
              id: 3,
              name: 'Остаться наедине с вечерней Москвой',
              link: 'https://www.afisha.ru/excursion/vechernyaya-moskva-74/',
              city: 1,
              image: 'events/hibiscus/moscow-5.jpg'
            },
            {
              id: 4,
              name: 'Раствориться в лунном свете',
              link: 'https://www.afisha.ru/concert/lunniy-svet-debyussi-ravel-sati-2265198/',
              city: 2,
              image: 'events/hibiscus/spb-1.jpg'
            },
            {
              id: 5,
              name: 'Наполниться энергией',
              link: 'https://www.afisha.ru/excursion/mesta-sily-karelii-dolina-vodopadov-i-zemlya-drevnih-499/',
              city: 2,
              image: 'events/hibiscus/spb-4.jpg'
            },
            {
              id: 6,
              name: 'Снять злые чары',
              link: 'https://www.afisha.ru/performance/lebedinoe-ozero-67136/',
              city: 2,
              image: 'events/hibiscus/spb-3.jpg'
            },
            {
              id: 7,
              name: 'Заглянуть в зеркало',
              link: 'https://www.afisha.ru/performance/palata-6-261490/?',
              city: 2,
              image: 'events/hibiscus/spb-5.jpg'
            },
            {
              id: 8,
              name: 'Зарядиться позитивом',
              link: 'https://www.afisha.ru/performance/slishkom-zhenatiy-taksist-191193/',
              city: 3,
              image: 'events/hibiscus/sochi-4.jpg'
            },
            {
              id: 9,
              name: 'Получить мощный заряд',
              link: 'https://www.afisha.ru/concert/rem-digga-2266435/',
              city: 3,
              image: 'events/hibiscus/sochi-5.jpg'
            },
            {
              id: 10,
              name: 'Услышать уникальный голос',
              link: 'https://www.afisha.ru/concert/merab-amzoev-2265173/',
              city: 3,
              image: 'events/hibiscus/sochi-6.jpg'
            },
          ],
          blacktea: [
            {
              id: 1,
              name: 'Любоваться работами Репина',
              link: 'https://www.afisha.ru/exhibition/master-repin-epizody-iz-zhizni-uchitelya-i-uchenikov-289626/',
              city: 1,
              image: 'events/black-tea/moscow-1.jpg'
            },
            {
              id: 2,
              name: 'Вернуться к истокам',
              link: 'https://www.afisha.ru/performance/lyubov-i-golubi-99720/',
              city: 1,
              image: 'events/black-tea/moscow-2.jpg'
            },
            {
              id: 3,
              name: 'Побывать в доме у писателя',
              link: 'https://www.afisha.ru/msk/schedule_excursion',
              city: 1,
              image: 'events/black-tea/moscow-3.jpg'
            },
            {
              id: 4,
              name: 'Вспомнить любимое советское кино',
              link: 'https://www.afisha.ru/performance/devchata-233124/',
              city: 2,
              image: 'events/black-tea/spb-1.jpg'
            },
            {
              id: 5,
              name: 'Утонуть в теплом свечном пламени',
              link: 'https://www.afisha.ru/concert/muzyka-pri-svechah-velichiyshaya-klassika-2212453/',
              city: 2,
              image: 'events/black-tea/spb-2.jpg'
            },
            {
              id: 6,
              name: 'Получить письмо из Хогвартса',
              link: 'https://www.afisha.ru/concert/garri-potter-orkestr-sonorus-2228842/',
              city: 2,
              image: 'events/black-tea/spb-3.jpg'
            },
            {
              id: 7,
              name: 'Любить до слез',
              link: 'https://www.afisha.ru/concert/aleksandr-serov-2266942/',
              city: 3,
              image: 'events/black-tea/sochi-1.jpg'
            },
            {
              id: 8,
              name: 'Заглянуть в соседний дом',
              link: 'https://www.afisha.ru/performance/hochu-kupit-vashego-muzha-67215/',
              city: 3,
              image: 'events/black-tea/sochi-2.jpg'
            },
            {
              id: 9,
              name: 'Улыбаться добрым шуткам',
              link: 'https://www.afisha.ru/concert/mariya-markova-2249571/',
              city: 3,
              image: 'events/black-tea/sochi-3.jpg'
            },
          ],
          greentea: [
            {
              id: 1,
              name: 'Понять спектакль без слов',
              link: 'https://www.afisha.ru/performance/antigravitaciya-299161/',
              city: 1,
              image: 'events/green-tea/moscow-1.jpg'
            },
            {
              id: 2,
              name: 'Переосмыслить фольклорные сюжеты',
              link: 'https://www.afisha.ru/concert/green-apelsin-2266254/?prioritydates=2024-06-15--2024-07-15',
              city: 1,
              image: 'events/green-tea/moscow-2.jpg'
            },
            {
              id: 3,
              name: 'Поужинать красиво',
              link: 'https://www.afisha.ru/concert/mad-girls-late-dinner-show-2249465/',
              city: 1,
              image: 'events/green-tea/moscow-3.jpg'
            },
            {
              id: 5,
              name: 'Проникнуть в секретную жизнь театра',
              link: 'https://www.afisha.ru/excursion/ekskursiya-blistatelniy-aleksandrinskiy-teatr-204/',
              city: 2,
              image: 'events/green-tea/spb-1.jpg'
            },
            {
              id: 6,
              name: 'Примерить амплуа художника',
              link: 'https://www.afisha.ru/concert/art-vecherinka-painty-2262371/',
              city: 2,
              image: 'events/green-tea/spb-2.jpg'
            },
            {
              id: 7,
              name: 'Стать свидетелем Великого потопа',
              link: 'https://www.afisha.ru/exhibition/art-gruppa-zerno-kovcheg-291334/',
              city: 2,
              image: 'events/green-tea/spb-3.jpg'
            },
            {
              id: 8,
              name: 'Проверить свое чувство юмора',
              link: 'https://www.afisha.ru/concert/ivan-abramov-novoe-i-luchshee-2265056/',
              city: 3,
              image: 'events/green-tea/sochi-1.jpg'
            },
            {
              id: 9,
              name: 'Полюбить панка',
              link: 'https://www.afisha.ru/concert/xolidayboy-2266457/',
              city: 3,
              image: 'events/green-tea/sochi-2.jpg'
            },
            {
              id: 10,
              name: 'Открыть юную звезду',
              link: 'https://www.afisha.ru/concert/amirchik-2226480/',
              city: 3,
              image: 'events/green-tea/sochi-3.jpg'
            },
          ],
          orange: [
            {
              id: 1,
              name: 'Ловить последние солнечные лучи',
              link: 'https://www.afisha.ru/concert/x-mezhdunarodniy-festival-dzhazovye-sezony-v-gorkah-leninskih-2228990/',
              city: 1,
              image: 'events/orange/moscow-1.jpg'
            },
            {
              id: 2,
              name: 'Танцевать навстречу ветру',
              link: 'https://www.afisha.ru/concert/triangle-sun-2264858/',
              city: 1,
              image: 'events/orange/moscow-2.jpg'
            },
            {
              id: 3,
              name: 'Окунуться в лето',
              link: 'https://www.afisha.ru/concert/cream-soda-summer-sound-x-bilayn-2265876/ ',
              city: 1,
              image: 'events/orange/moscow-3.jpg'
            },
            {
              id: 4,
              name: 'Нырнуть в море рока',
              link: 'https://www.afisha.ru/concert/mirovye-rok-hity-na-kryshe-s-simfonicheskim-orkestrom-2187688/',
              city: 2,
              image: 'events/orange/spb-1.jpg'
            },
            {
              id: 5,
              name: 'Поймать в тропиках уходящее лето',
              link: 'https://www.afisha.ru/concert/muzyka-v-oranzheree-2164011/',
              city: 2,
              image: 'events/orange/spb-2.jpg'
            },
            {
              id: 6,
              name: 'Смотреть на звезды и разводные мосты',
              link: 'https://www.afisha.ru/concert/gidrovoyazh-vecherinka-na-teplohode-v-sankt-peterburge-pod-razvodnye-mosty-2267432/',
              city: 2,
              image: 'events/orange/spb-3.jpg'
            },
            {
              id: 7,
              name: 'Почувствовать себя пиратом',
              link: 'https://www.afisha.ru/concert/piratskaya-vecherinka-na-teplohode-dagomys-2267360/',
              city: 3,
              image: 'events/orange/sochi-1.jpg'
            },
            {
              id: 8,
              name: 'Найти малиновый свет',
              link: 'https://www.afisha.ru/concert/lesha-svik-2265208/',
              city: 3,
              image: 'events/orange/sochi-2.jpg'
            },
            {
              id: 9,
              name: 'Ловить летний ветер',
              link: 'https://www.afisha.ru/concert/dzhazovye-vecherinki-na-teplohode-dagomys-2267359/',
              city: 3,
              image: 'events/orange/sochi-3.jpg'
            },
          ],
          ginger: [
            {
              id: 1,
              name: 'Отправиться в Древний Египет',
              link: 'https://www.afisha.ru/exhibition/cifrovoe-shou-tutanhamon-v-poiskah-vechnoy-zhizni-290969/',
              city: 1,
              image: 'events/ginger/moscow-1.jpg'
            },
            {
              id: 2,
              name: 'Побывать в русской деревне',
              link: 'https://www.afisha.ru/excursion/moskva-krutoyak-605/',
              city: 1,
              image: 'events/ginger/moscow-2.jpg'
            },
            {
              id: 3,
              name: 'Покормить верблюдов',
              link: 'https://www.afisha.ru/excursion/den-v-etnoparke-kochevnik-46/',
              city: 1,
              image: 'events/ginger/moscow-3.jpg'
            },
            {
              id: 4,
              name: 'Запрыгнуть на трамвай “302-БиС”',
              link: 'https://www.afisha.ru/excursion/avtobusnaya-ekskursiya-bulgakov-i-ego-epoha-39/',
              city: 1,
              image: 'events/ginger/moscow-4.jpg'
            },
            {
              id: 6,
              name: 'Отправиться на поиски жемчуга',
              link: 'https://www.afisha.ru/performance/iskateli-zhemchuga-115270/',
              city: 2,
              image: 'events/ginger/spb-1.jpg'
            },
            {
              id: 7,
              name: 'Гулять по Питеру и смеяться',
              link: 'https://www.afisha.ru/excursion/stendap-ekskursiya-po-sankt-peterburgu-185/',
              city: 2,
              image: 'events/ginger/spb-2.jpg'
            },
            {
              id: 8,
              name: 'Отправиться в бескрайний космос',
              link: 'https://www.afisha.ru/concert/koncert-pod-zvezdami-interstellar-2122187/',
              city: 2,
              image: 'events/ginger/spb-3.jpg'
            },
            {
              id: 9,
              name: 'Удивиться красотам Карелии',
              link: 'https://www.afisha.ru/excursion/zhemchuzhina-karelii-ruskeala-300/',
              city: 2,
              image: 'events/ginger/spb-4.jpg'
            },
            {
              id: 10,
              name: 'Побывать в европейском городе',
              link: 'https://www.afisha.ru/excursion/ekskursiya-v-vyborg-po-korolevskiy-doroge-309/',
              city: 2,
              image: 'events/ginger/spb-5.jpg'
            },
            {
              id: 11,
              name: 'Раствориться в ночном Петербурге',
              link: 'https://www.afisha.ru/excursion/bolshaya-progulka-megapolis-i-razvodnye-mosty-s-nochnym-vyhodom-v-finskiy-zaliv-avtorskiy-ekskursiiy-i-zhiviy-muzykiy-467/',
              city: 2,
              image: 'events/ginger/spb-6.jpg'
            },
            {
              id: 12,
              name: 'Понять панк-культуру',
              link: 'https://www.afisha.ru/exhibition/pank-kultura-korol-i-shut-287390/',
              city: 2,
              image: 'events/ginger/spb-7.jpg'
            },
            {
              id: 13,
              name: 'Узнать городские легенды',
              link: 'https://www.afisha.ru/performance/teatralizovannye-ekskursii-s-fonarshchikom-farolero-marshrut-sochi-centralniy-260560/?prioritydates=2024-06-14--2024-07-20',
              city: 3,
              image: 'events/ginger/sochi-1.jpg'
            },
            {
              id: 14,
              name: 'Почувствовать себя мафиози',
              link: 'https://www.afisha.ru/exhibition/igra-mafiya-290584/',
              city: 3,
              image: 'events/ginger/sochi-2.jpg'
            },
            {
              id: 15,
              name: 'Вернуться в 90-ые',
              link: 'https://www.afisha.ru/concert/vecherinka-diskoteka-90-h-na-teplohode-dagomys-2267361/',
              city: 3,
              image: 'events/ginger/sochi-3.jpg'
            },
          ],
          grapefruit: [
            {
              id: 1,
              name: 'Слушать легенды и открывать имена',
              link: 'https://www.afisha.ru/concert/piknik-afishi-2024-moskva-2263454/',
              city: 1,
              image: 'events/grapefruit/moscow-1.jpg'
            },
            {
              id: 2,
              name: 'Играть в шахматы под джаз',
              link: 'https://www.afisha.ru/concert/chess-jazz-2024-2261331/',
              city: 1,
              image: 'events/grapefruit/moscow-2.jpg'
            },
            {
              id: 3,
              name: 'Полюбить честный юмор',
              link: 'https://www.afisha.ru/concert/zhenskiy-stendap-v-zelenom-teatre-vdnh-2267138/',
              city: 1,
              image: 'events/grapefruit/moscow-3.jpg'
            },
            {
              id: 4,
              name: 'Слушать легенды и открывать имена',
              link: 'https://www.afisha.ru/concert/piknik-afishi-2024-sankt-peterburg-2263455/',
              city: 2,
              image: 'events/grapefruit/spb-1.jpg'
            },
            {
              id: 5,
              name: 'Вспомнить, что вам всегда 18',
              link: 'https://www.afisha.ru/concert/ruki-vverh-2261646/',
              city: 2,
              image: 'events/grapefruit/spb-2.jpg'
            },
            {
              id: 6,
              name: 'Сбежать в Одиссею',
              link: 'https://www.afisha.ru/concert/odyssey-friends-family-2267440/',
              city: 2,
              image: 'events/grapefruit/spb-3.jpg'
            },
            {
              id: 7,
              name: 'Отправиться в отрыв',
              link: 'https://www.afisha.ru/concert/gspd-dead-blonde-2266406/',
              city: 3,
              image: 'events/grapefruit/sochi-1.jpg'
            },
            {
              id: 8,
              name: 'Посмеяться от души',
              link: 'https://www.afisha.ru/concert/andrey-beburishvili-2212368/',
              city: 3,
              image: 'events/grapefruit/sochi-2.jpg'
            },
            {
              id: 9,
              name: 'Сходить на стендап',
              link: 'https://www.afisha.ru/concert/guram-amaryan-2264710/',
              city: 3,
              image: 'events/grapefruit/sochi-3.jpg'
            },
          ],
        }
      }
    },
		components: {
			Header,
			HeaderColor,
			Footer,
		},
    computed: {
      mskEventHibicus() {
        return this.getRandomEventByCityAndType(1, 'hibiscus');
      },
      spbEventHibicus() {
        return this.getRandomEventByCityAndType(2, 'hibiscus');
      },
      sochiEventHibicus() {
        return this.getRandomEventByCityAndType(3, 'hibiscus');
      },
      mskEventBlackTea() {
        return this.getRandomEventByCityAndType(1, 'blacktea');
      },
      spbEventBlackTea() {
        return this.getRandomEventByCityAndType(2, 'blacktea');
      },
      sochiEventBlackTea() {
        return this.getRandomEventByCityAndType(3, 'blacktea');
      },
      mskEventGreenTea() {
        return this.getRandomEventByCityAndType(1, 'greentea');
      },
      spbEventGreenTea() {
        return this.getRandomEventByCityAndType(2, 'greentea');
      },
      sochiEventGreenTea() {
        return this.getRandomEventByCityAndType(3, 'greentea');
      },
      mskEventOrange() {
        return this.getRandomEventByCityAndType(1, 'orange');
      },
      spbEventOrange() {
        return this.getRandomEventByCityAndType(2, 'orange');
      },
      sochiEventOrange() {
        return this.getRandomEventByCityAndType(3, 'orange');
      },
      mskEventGinger() {
        return this.getRandomEventByCityAndType(1, 'ginger');
      },
      spbEventGinger() {
        return this.getRandomEventByCityAndType(2, 'ginger');
      },
      sochiEventGinger() {
        return this.getRandomEventByCityAndType(3, 'ginger');
      },
      mskEventGrapefruit() {
        return this.getRandomEventByCityAndType(1, 'grapefruit');
      },
      spbEventGrapefruit() {
        return this.getRandomEventByCityAndType(2, 'grapefruit');
      },
      sochiEventGrapefruit() {
        return this.getRandomEventByCityAndType(3, 'grapefruit');
      },
    },
    methods: {
      getRandomEventByCityAndType(cityId, eventType) {
        let events = this.events[eventType].filter(e => e.city == cityId);
        let randomEvent = events[Math.floor(Math.random() * events.length)];
        return randomEvent;
      },
      buy() {
        if (this.isBlackTea) {
          this.sendEvent('buy_blackTea');
        }
        if (this.isGreenTea) {
          this.sendEvent('buy_greenTea');
        }
        if (this.isGinger) {
          this.sendEvent('buy_imbir');
        }
        if (this.isGrapefruit) {
          this.sendEvent('buy_grapefruit');
        }
        if (this.isHibiscus) {
          this.sendEvent('buy_karkade');
        }
        if (this.isOrange) {
          this.sendEvent('buy_lemongrass');
        }
      }
    },
		mounted() {
      if (this.active == 1) {
        this.isGreenTea = true;
        this.link = "https://eda.yandex.ru/search?query=холодный%20чай%20мираторг&type=all"
      }
      if (this.active == 2) {
        this.isGinger = true;
      }
      if (this.active == 3) {
        this.isBlackTea = true;
        this.link = "https://eda.yandex.ru/search?query=холодный%20чай%20мираторг&type=all"
      }
      if (this.active == 4) {
        this.isOrange = true;
      }
      if (this.active == 5) {
        this.isHibiscus = true;
        this.link = "https://eda.yandex.ru/search?query=холодный%20чай%20мираторг&type=all"
      }
      if (this.active == 6) {
        this.isGrapefruit = true;
      }
      setTimeout(() => {
        AOS.init({
          duration: 800
        });
      }, 600)
    },
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
@import "../sass/styles.sass"
</style>