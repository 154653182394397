<template>
	<div class="products">
		<Header />
    <div class="products__content">
      <div class="products__content-wrapper">
        <h1>Выбери вкус своего лета</h1>
        <div class="products__swiper">
          <div class="swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="slider">
                  <div class="slider__item color-1" :class="{active: opened == 1}" @click="openCard(1, $event)">
                    <div class="slider__image">
                      <img src="@/assets/img/lemonade-4.png" alt="">
                    </div>
                    <div class="slider__content">
                      <div class="slider__content-wrapper">
                        <h2>Чай каркаде <br>со вкусом земляники</h2>
                        <p>Сладковато-кислый вкус <br>чая из гибискуса по‑особенному раскрывается благодаря натуральной землянике. <br>Напиток отлично охлаждает <br>в жару, а благодаря особой технологии розлива <br>максимально сохраняет пользу ингредиентов.</p>
                        <a href="https://eda.yandex.ru/search?query=холодный%20чай%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slider">
                  <div class="slider__item color-2" :class="{active: opened == 2}" @click="openCard(2, $event)">
                    <div class="slider__image">
                      <img src="@/assets/img/lemonade-3.png" alt="">
                    </div>
                    <div class="slider__content">
                      <div class="slider__content-wrapper">
                        <h2>Зелёный чай со вкусом клубники и базилика</h2>
                        <p>Холодный напиток с изысканным тонким вкусом зарядит энергией, которой хватит на яркий и насыщенный солнечный день. Клубника добавит свежести, а базилик поможет раскрыться привычным вкусам с новой стороны.</p>
                        <a href="https://eda.yandex.ru/search?query=холодный%20чай%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slider">
                  <div class="slider__item color-3" :class="{active: opened == 3}" @click="openCard(3, $event)">
                    <div class="slider__image">
                      <img src="@/assets/img/lemonade-5.png" alt="">
                    </div>
                    <div class="slider__content">
                      <div class="slider__content-wrapper">
                        <h2>Чёрный чай <br>со вкусом малины</h2>
                        <p>Освежающий напиток <br>из чёрного чая поможет взбодриться, а яркий вкус малины навеет приятные воспоминания из детства. <br>Та самая по-домашнему вкусная альтернатива соку <br>или газировке, которую одобрит даже твоя бабушка.</p>
                        <a href="https://eda.yandex.ru/search?query=холодный%20чай%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slider">
                  <div class="slider__item color-4" :class="{active: opened == 4}" @click="openCard(4, $event)">
                    <div class="slider__image">
                      <img src="@/assets/img/lemonade-6.png" alt="">
                    </div>
                    <div class="slider__content">
                      <div class="slider__content-wrapper">
                        <h2>Лимонад <br>из апельсина с лемонграссом</h2>
                        <p>Бодрящий напиток <br>из солнечного апельсина <br>и освежающего лемонграсса — то что нужно для жаркого летнего дня. Лимонад <br>из сладкого цитруса наполнен свежестью и пользой, <br>чтобы зарядить вас энергией <br>и придать сил надолго.</p>
                        <a href="https://eda.yandex.ru/search?query=лимонад%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slider">
                  <div class="slider__item color-5" :class="{active: opened == 5}" @click="openCard(5, $event)">
                    <div class="slider__image">
                      <img src="@/assets/img/lemonade-2.png" alt="">
                    </div>
                    <div class="slider__content">
                      <div class="slider__content-wrapper">
                        <h2>Лимонад из грейпфрута</h2>
                        <p>Яркий и освежающий прохладный лимонад <br>из грейпфрута отлично утолит жажду и в знойный полдень <br>и тёплым летним вечером. Натуральный, с тонким послевкусием и пикантной горчинкой он прекрасно миксуется с другими напитками для получения новых незабываемых впечатлений.</p>
                        <a href="https://eda.yandex.ru/search?query=лимонад%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slider">
                  <div class="slider__item color-6" :class="{active: opened == 6}" @click="openCard(6, $event)">
                    <div class="slider__image">
                      <img src="@/assets/img/lemonade-1.png" alt="">
                    </div>
                    <div class="slider__content">
                      <div class="slider__content-wrapper">
                        <h2>Лимонад <br>с имбирём</h2>
                        <p>Лимонад из спелых плодов лимона и ароматного имбиря отлично освежит в жару <br>и придаст витаминный заряд бодрости. Отборное сырьё <br>и технология бережного приготовления позоляет сохранить максимум пользы <br>и вкуса.</p>
                        <a href="https://eda.yandex.ru/search?query=лимонад%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <router-link to="/" class="products__link"><span @click="sendEvent('click_goBack')">вернуться</span></router-link>
            <div class="slider-buttons">
              <div class="slider-button slider-button-prev" @click="slidePrev"></div>
              <div class="slider-button slider-button-next" @click="slideNext"></div>
            </div>
          </div>
        </div>
        <div class="products__cards">
          <div class="slider">
            <div class="slider__item color-1" :class="{active: opened == 1}" @click="openCard(1, $event)">
              <div class="slider__image">
                <img src="@/assets/img/lemonade-4.png" alt="">
              </div>
              <div class="slider__content">
                <div class="slider__content-wrapper">
                  <h2>Чай каркаде <br>со вкусом земляники</h2>
                  <p>Сладковато-кислый вкус <br>чая из гибискуса по‑особенному раскрывается благодаря натуральной землянике. <br>Напиток отлично охлаждает <br>в жару, а благодаря особой технологии розлива <br>максимально сохраняет пользу ингредиентов.</p>
                  <a href="https://eda.yandex.ru/search?query=холодный%20чай%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                </div>
              </div>
            </div>
          </div>
          <div class="slider">
            <div class="slider__item color-2" :class="{active: opened == 2}" @click="openCard(2, $event)">
              <div class="slider__image">
                <img src="@/assets/img/lemonade-3.png" alt="">
              </div>
              <div class="slider__content">
                <div class="slider__content-wrapper">
                  <h2>Зелёный чай со вкусом клубники и базилика</h2>
                  <p>Холодный напиток с изысканным тонким вкусом зарядит энергией, которой хватит на яркий и насыщенный солнечный день. Клубника добавит свежести, а базилик поможет раскрыться привычным вкусам с новой стороны.</p>
                  <a href="https://eda.yandex.ru/search?query=холодный%20чай%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                </div>
              </div>
            </div>
          </div>
          <div class="slider">
            <div class="slider__item color-3" :class="{active: opened == 3}" @click="openCard(3, $event)">
              <div class="slider__image">
                <img src="@/assets/img/lemonade-5.png" alt="">
              </div>
              <div class="slider__content">
                <div class="slider__content-wrapper">
                  <h2>Чёрный чай <br>со вкусом малины</h2>
                  <p>Освежающий напиток <br>из чёрного чая поможет взбодриться, а яркий вкус малины навеет приятные воспоминания из детства. <br>Та самая по-домашнему вкусная альтернатива соку <br>или газировке, которую одобрит даже твоя бабушка.</p>
                  <a href="https://eda.yandex.ru/search?query=холодный%20чай%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                </div>
              </div>
            </div>
          </div>
          <div class="slider">
            <div class="slider__item color-4" :class="{active: opened == 4}" @click="openCard(4, $event)">
              <div class="slider__image">
                <img src="@/assets/img/lemonade-6.png" alt="">
              </div>
              <div class="slider__content">
                <div class="slider__content-wrapper">
                  <h2>Лимонад <br>из апельсина с лемонграссом</h2>
                  <p>Бодрящий напиток <br>из солнечного апельсина <br>и освежающего лемонграсса — то что нужно для жаркого летнего дня. Лимонад <br>из сладкого цитруса наполнен свежестью и пользой, <br>чтобы зарядить вас энергией <br>и придать сил надолго.</p>
                  <a href="https://eda.yandex.ru/search?query=лимонад%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                </div>
              </div>
            </div>
          </div>
          <div class="slider">
            <div class="slider__item color-5" :class="{active: opened == 5}" @click="openCard(5, $event)">
              <div class="slider__image">
                <img src="@/assets/img/lemonade-2.png" alt="">
              </div>
              <div class="slider__content">
                <div class="slider__content-wrapper">
                  <h2>Лимонад из грейпфрута</h2>
                  <p>Яркий и освежающий прохладный лимонад <br>из грейпфрута отлично утолит жажду и в знойный полдень <br>и тёплым летним вечером. Натуральный, с тонким послевкусием и пикантной горчинкой он прекрасно миксуется с другими напитками для получения новых незабываемых впечатлений.</p>
                  <a href="https://eda.yandex.ru/search?query=лимонад%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                </div>
              </div>
            </div>
          </div>
          <div class="slider">
            <div class="slider__item color-6" :class="{active: opened == 6}" @click="openCard(6, $event)">
              <div class="slider__image">
                <img src="@/assets/img/lemonade-1.png" alt="">
              </div>
              <div class="slider__content">
                <div class="slider__content-wrapper">
                  <h2>Лимонад <br>с имбирём</h2>
                  <p>Лимонад из спелых плодов лимона и ароматного имбиря отлично освежит в жару <br>и придаст витаминный заряд бодрости. Отборное сырьё <br>и технология бережного приготовления позоляет сохранить максимум пользы <br>и вкуса.</p>
                  <a href="https://eda.yandex.ru/search?query=лимонад%20мираторг&type=all" target="_blank" class="slider__button">Купить</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <router-link to="/" class="products__link">вернуться</router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer class="grey" />
	</div>
</template>

<script>
  import Swiper from 'swiper';
  import 'swiper/swiper-bundle.css';
	import Header from '@/components/HeaderColor'
	import Footer from '@/components/Footer'
  export default {
		name: "Result",
    data() {
      return {
        slider: null,
        opened: 0,
      }
    },
		components: {
			Header,
			Footer,
		},
    mounted() {
      this.slider = new Swiper('.swiper', {
        direction: 'horizontal',
				slidesPerView: 4.829,
				loop: false,
			});
    },
    methods: {
      slideNext() {
        this.slider.slideNext();
      },
      slidePrev() {
        this.slider.slidePrev();
      },
      openCard(id, e) {
        if(e.target.classList.contains('slider__button')) {
          if (id == 1) {
            this.sendEvent('click_buyCardKarkade')
          }
          if (id == 2) {
            this.sendEvent('click_buyCardGreenTea')
          }
          if (id == 3) {
            this.sendEvent('click_buyCardBlackTea')
          }
          if (id == 4) {
            this.sendEvent('click_buyCardLemongrass')
          }
          if (id == 5) {
            this.sendEvent('click_buyCardGrapefruit')
          }
          if (id == 6) {
            this.sendEvent('click_buyCardImbir')
          }
        } else {
          if (id == this.opened) {
            this.opened = 0;
          } else {
            this.opened = id;
          }
          if (id == 1) {
            this.sendEvent('click_cardKarkade')
          }
          if (id == 2) {
            this.sendEvent('click_cardGreenTea')
          }
          if (id == 3) {
            this.sendEvent('click_cardBlackTea')
          }
          if (id == 4) {
            this.sendEvent('click_cardLemongrass')
          }
          if (id == 5) {
            this.sendEvent('click_cardGrapefruit')
          }
          if (id == 6) {
            this.sendEvent('click_cardImbir')
          }
        }
			}
    },
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
@import "../sass/styles.sass"
</style>