<template>
  <div class="loader-overlay">
    
      <div class="hero__button loader-item" >
        <div class="hero__button-text">
          <svg width="145" height="145" viewBox="0 0 145 145" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M72.1316 141.37L70.1928 141.287C69.3634 141.252 68.7405 141.109 68.3243 140.858C67.9081 140.607 67.7125 140.189 67.7373 139.604C67.7473 139.368 67.7947 139.156 67.8795 138.969C67.9615 138.782 68.076 138.631 68.2233 138.514C68.3705 138.398 68.5471 138.326 68.7532 138.298L68.755 138.255C68.5495 138.204 68.3651 138.122 68.202 138.01C68.0388 137.9 67.9116 137.744 67.8207 137.541C67.7267 137.341 67.6867 137.077 67.7006 136.751C67.7166 136.373 67.8227 136.053 68.0188 135.791C68.2149 135.529 68.4879 135.333 68.8378 135.203C69.1847 135.075 69.5939 135.021 70.0654 135.041L72.3962 135.14L72.1316 141.37ZM70.9155 138.847L70.1485 138.814C69.765 138.798 69.4969 138.846 69.3441 138.959C69.1912 139.075 69.1097 139.251 69.0997 139.487C69.0896 139.725 69.1704 139.899 69.3422 140.009C69.511 140.122 69.7843 140.186 70.1621 140.202L70.8566 140.231L70.9155 138.847ZM70.96 137.798L71.029 136.175L70.1682 136.138C69.7705 136.121 69.4903 136.186 69.3276 136.333C69.1648 136.48 69.078 136.683 69.067 136.941C69.0605 137.094 69.0888 137.231 69.1521 137.35C69.2153 137.47 69.3265 137.565 69.4856 137.638C69.6419 137.71 69.8607 137.752 70.1419 137.764L70.96 137.798Z" fill="#E50046"/>
            <path d="M66.6051 134.869L65.6722 141.034L64.365 140.836L64.7229 138.471L64.3013 138.407C63.7306 138.321 63.2747 138.17 62.9335 137.954C62.5894 137.738 62.3504 137.475 62.2162 137.164C62.0817 136.856 62.0418 136.521 62.0967 136.158C62.189 135.548 62.4631 135.105 62.9189 134.829C63.372 134.552 64.0385 134.481 64.9184 134.614L66.6051 134.869ZM65.1358 135.742L64.7942 135.691C64.4091 135.632 64.0959 135.65 63.8547 135.743C63.6131 135.838 63.4684 136.044 63.4208 136.359C63.3876 136.578 63.4129 136.753 63.4966 136.883C63.5804 137.014 63.7161 137.115 63.9037 137.186C64.0912 137.258 64.3242 137.315 64.6025 137.357L64.885 137.4L65.1358 135.742ZM61.6377 134.117L60.7048 140.282L59.4018 140.085L60.3347 133.92L61.6377 134.117Z" fill="#E50046"/>
            <path d="M59.0268 133.589L57.4645 139.625L53.6496 138.638L53.9231 137.581L56.4582 138.237L56.7841 136.977L56.2804 136.847C55.7134 136.7 55.2685 136.501 54.9458 136.251C54.6203 135.999 54.4068 135.712 54.3054 135.389C54.2004 135.068 54.1939 134.73 54.2858 134.375C54.4404 133.778 54.76 133.365 55.2446 133.138C55.7264 132.911 56.4009 132.909 57.2679 133.133L59.0268 133.589ZM57.4754 134.306L57.0461 134.195C56.658 134.095 56.3364 134.077 56.0814 134.144C55.8257 134.212 55.6579 134.401 55.5781 134.709C55.5226 134.924 55.5352 135.102 55.6161 135.243C55.6943 135.384 55.8298 135.501 56.0228 135.595C56.2131 135.689 56.4486 135.772 56.7293 135.844L57.0555 135.929L57.4754 134.306Z" fill="#E50046"/>
            <path d="M49.3351 137.2C48.5787 136.925 48.0858 136.562 47.8565 136.11C47.6262 135.661 47.6142 135.152 47.8206 134.582C47.9136 134.326 48.0415 134.094 48.2043 133.887C48.3661 133.682 48.5681 133.521 48.8104 133.403C49.05 133.284 49.3349 133.224 49.665 133.223C49.9942 133.224 50.3726 133.302 50.8003 133.457L51.3335 133.651L52.0892 131.566L53.3322 132.016L51.2076 137.878L49.3351 137.2ZM49.7684 136.204L50.3338 136.409L50.9644 134.669L50.5555 134.521C50.3229 134.437 50.1099 134.394 49.9166 134.394C49.7232 134.393 49.556 134.442 49.415 134.539C49.274 134.636 49.1662 134.787 49.0916 134.993C48.9869 135.282 48.9896 135.525 49.0996 135.722C49.2087 135.921 49.4316 136.082 49.7684 136.204Z" fill="#E50046"/>
            <path d="M44.3995 128.293L44.169 129.827L46.2209 130.805L47.2676 129.66L48.5534 130.273L43.8728 134.978L42.4138 134.282L43.1137 127.68L44.3995 128.293ZM43.9767 130.964L43.7608 132.467C43.7448 132.567 43.7263 132.695 43.7051 132.852C43.6801 133.01 43.6557 133.17 43.6321 133.332C43.6046 133.495 43.5786 133.636 43.5541 133.753C43.63 133.66 43.7283 133.546 43.8491 133.412C43.9662 133.279 44.0782 133.153 44.1854 133.034C44.2925 132.915 44.3691 132.83 44.4152 132.779L45.4434 131.663L43.9767 130.964Z" fill="#E50046"/>
            <path d="M39.7712 125.661L40.902 126.346L38.2417 130.738L39.6899 131.615L39.1198 132.556L35.0927 130.117L35.6627 129.175L37.1109 130.053L39.7712 125.661Z" fill="#E50046"/>
            <path d="M37.1712 123.987L33.4667 129.003L32.4033 128.217L33.8248 126.293L33.4063 125.984C32.9351 125.636 32.5968 125.285 32.3912 124.932C32.1833 124.577 32.0926 124.23 32.1192 123.893C32.1417 123.556 32.262 123.24 32.4799 122.945C32.8465 122.449 33.2969 122.186 33.8312 122.157C34.3633 122.125 34.9895 122.376 35.7099 122.908L37.1712 123.987ZM35.4642 124.073L35.1074 123.81C34.785 123.571 34.4931 123.435 34.2318 123.402C33.9689 123.37 33.7428 123.482 33.5536 123.738C33.4219 123.917 33.3671 124.087 33.3893 124.248C33.4092 124.408 33.4912 124.567 33.6351 124.726C33.7767 124.884 33.9641 125.049 34.1974 125.221L34.4684 125.421L35.4642 124.073Z" fill="#E50046"/>
            <path d="M24.92 120.538C25.1015 120.715 25.2973 120.835 25.5074 120.901C25.7134 120.966 25.9287 120.979 26.1534 120.94C26.3759 120.898 26.6028 120.807 26.834 120.667C27.0652 120.527 27.2947 120.34 27.5226 120.105C27.8296 119.789 28.0533 119.481 28.1938 119.182C28.3302 118.882 28.3732 118.593 28.3229 118.314C28.2706 118.038 28.114 117.772 27.8529 117.519C27.6714 117.343 27.4701 117.187 27.249 117.051C27.0258 116.914 26.7705 116.779 26.483 116.646L27.2556 115.851C27.5327 115.961 27.7839 116.092 28.0093 116.244C28.2367 116.393 28.4697 116.584 28.7083 116.816C29.1692 117.263 29.4564 117.727 29.5699 118.206C29.6794 118.685 29.6392 119.165 29.4491 119.646C29.2571 120.129 28.9382 120.6 28.4925 121.059C28.1636 121.398 27.8166 121.663 27.4515 121.856C27.0863 122.048 26.715 122.161 26.3375 122.195C25.96 122.228 25.5862 122.174 25.2162 122.033C24.8462 121.891 24.4919 121.656 24.1534 121.327C23.9311 121.112 23.7365 120.867 23.5697 120.594C23.3988 120.321 23.259 120.037 23.1501 119.741L24.2049 119.267C24.2948 119.509 24.3957 119.742 24.5076 119.965C24.6194 120.189 24.7569 120.38 24.92 120.538Z" fill="#E50046"/>
            <path d="M21.7651 118.746L20.5016 117.273C19.961 116.643 19.6404 116.09 19.5396 115.615C19.4389 115.139 19.6108 114.711 20.0553 114.33C20.2344 114.176 20.4212 114.066 20.6158 114C20.8085 113.932 20.9975 113.913 21.1829 113.941C21.3683 113.969 21.5416 114.049 21.7029 114.18L21.7353 114.153C21.6325 113.967 21.5665 113.777 21.5371 113.581C21.5055 113.387 21.5329 113.187 21.6192 112.982C21.7014 112.777 21.8666 112.568 22.1148 112.355C22.4018 112.109 22.7079 111.968 23.0333 111.932C23.3586 111.896 23.6883 111.962 24.0224 112.129C24.3524 112.295 24.6711 112.557 24.9784 112.916L26.4974 114.686L21.7651 118.746ZM22.7784 116.135L22.2785 115.552C22.0286 115.261 21.8102 115.098 21.6233 115.063C21.4343 115.031 21.2502 115.091 21.0711 115.245C20.8898 115.4 20.8177 115.578 20.8548 115.779C20.8879 115.979 21.0276 116.223 21.2738 116.51L21.7264 117.037L22.7784 116.135ZM23.5747 115.452L24.8079 114.394L24.247 113.74C23.9878 113.438 23.749 113.277 23.5307 113.258C23.3123 113.24 23.105 113.315 22.9086 113.483C22.7921 113.583 22.7118 113.697 22.6677 113.825C22.6237 113.952 22.6296 114.099 22.6855 114.265C22.7395 114.428 22.8582 114.616 23.0415 114.83L23.5747 115.452Z" fill="#E50046"/>
            <path d="M17.0312 107.504C17.4249 107.228 17.8178 107.026 18.2101 106.897C18.6 106.77 18.9815 106.725 19.3547 106.763C19.7262 106.798 20.0816 106.924 20.421 107.141C20.758 107.36 21.0724 107.677 21.3642 108.094C21.656 108.511 21.847 108.916 21.9371 109.307C22.0248 109.7 22.0223 110.079 21.9296 110.442C21.8352 110.803 21.6604 111.146 21.4051 111.47C21.1497 111.795 20.8241 112.095 20.4281 112.372C19.8993 112.743 19.3789 112.978 18.867 113.08C18.351 113.181 17.8584 113.126 17.3891 112.917C16.9198 112.707 16.4903 112.324 16.1007 111.768C15.7127 111.213 15.5023 110.682 15.4695 110.174C15.4351 109.664 15.5544 109.184 15.8274 108.737C16.0988 108.286 16.5001 107.875 17.0312 107.504ZM19.6403 111.232C19.9967 110.983 20.2733 110.723 20.4699 110.454C20.6625 110.183 20.7619 109.904 20.768 109.615C20.7717 109.328 20.6677 109.033 20.4557 108.73C20.2405 108.423 19.9974 108.221 19.7263 108.127C19.4528 108.033 19.1578 108.033 18.8413 108.127C18.5208 108.219 18.1823 108.39 17.8259 108.639C17.2901 109.014 16.9386 109.41 16.7713 109.825C16.6041 110.241 16.681 110.678 17.0021 111.137C17.2157 111.442 17.46 111.642 17.7351 111.738C18.0062 111.833 18.3028 111.835 18.625 111.745C18.9432 111.654 19.2816 111.483 19.6403 111.232Z" fill="#E50046"/>
            <path d="M13.391 107.492L12.824 106.446L15.4711 105.011C15.6011 104.941 15.7461 104.865 15.9063 104.785C16.0652 104.702 16.2228 104.623 16.3794 104.548C16.5321 104.472 16.666 104.405 16.7812 104.35C16.8964 104.294 16.9741 104.258 17.0143 104.243L17.0021 104.22L11.4684 103.945L10.7144 102.554L16.1961 99.5828L16.757 100.618L14.1249 102.044C13.9849 102.12 13.8311 102.2 13.6634 102.285C13.4957 102.369 13.3324 102.45 13.1733 102.526C13.0129 102.6 12.8745 102.664 12.7579 102.717C12.6389 102.772 12.5599 102.809 12.5211 102.826L12.5373 102.856L18.1146 103.122L18.8727 104.521L13.391 107.492ZM9.19347 104.053C9.47333 103.937 9.74769 103.885 10.0165 103.898C10.2854 103.91 10.5465 104.016 10.7999 104.215C11.0533 104.414 11.2992 104.734 11.5377 105.174C11.7829 105.626 11.9202 106.008 11.9496 106.318C11.9764 106.63 11.919 106.894 11.7773 107.11C11.6331 107.328 11.4296 107.522 11.1669 107.693L10.612 106.67C10.8823 106.494 11.0311 106.321 11.0586 106.151C11.0835 105.983 11.0242 105.766 10.8806 105.501C10.7614 105.281 10.6138 105.128 10.438 105.042C10.2621 104.956 10.0342 104.971 9.75439 105.088L9.19347 104.053Z" fill="#E50046"/>
            <path d="M8.49924 97.4679L7.84491 95.641C7.56496 94.8595 7.46282 94.2286 7.5385 93.7485C7.61418 93.2684 7.92771 92.9296 8.47911 92.7321C8.70127 92.6525 8.91497 92.6167 9.12019 92.6248C9.32446 92.6301 9.5081 92.6791 9.67114 92.7717C9.83417 92.8643 9.9676 93.0007 10.0714 93.181L10.1116 93.1666C10.0817 92.9568 10.0881 92.7553 10.1305 92.5619C10.1703 92.3694 10.2671 92.1928 10.4209 92.032C10.5711 91.8695 10.8001 91.7331 11.1079 91.6229C11.4639 91.4953 11.8002 91.473 12.1168 91.5559C12.4334 91.6389 12.718 91.8178 12.9705 92.0928C13.2194 92.366 13.4234 92.7248 13.5826 93.1692L14.3692 95.3654L8.49924 97.4679ZM10.3781 95.3906L10.1193 94.6679C9.98984 94.3065 9.84399 94.0764 9.68171 93.9775C9.51676 93.8795 9.3232 93.8703 9.10104 93.9499C8.8762 94.0304 8.74532 94.1709 8.7084 94.3714C8.66785 94.5701 8.71132 94.8475 8.83883 95.2035L9.07324 95.8579L10.3781 95.3906ZM11.3658 95.0368L12.8955 94.4889L12.605 93.6778C12.4708 93.3031 12.3051 93.068 12.1078 92.9726C11.9105 92.8771 11.6901 92.873 11.4465 92.9603C11.302 93.012 11.1864 93.0897 11.0996 93.1933C11.0129 93.2968 10.9661 93.4359 10.9591 93.6105C10.9513 93.7825 10.9948 94.0009 11.0897 94.2659L11.3658 95.0368Z" fill="#E50046"/>
            <path d="M11.2293 85.0291L11.6054 86.4824L9.11233 89.4624L12.1717 88.6706L12.503 89.9505L6.46675 91.5128L6.1355 90.2329L9.05866 89.4763L5.57557 88.0694L5.22295 86.7069L8.69088 88.1574L11.2293 85.0291Z" fill="#E50046"/>
            <path d="M4.17154 80.4119L8.83483 81.6088C9.25561 81.7159 9.6235 81.8431 9.93849 81.9905C10.2507 82.1384 10.5068 82.3486 10.7069 82.6214C10.9041 82.8946 11.0421 83.2725 11.1208 83.7551C11.1455 83.9067 11.1611 84.0727 11.1675 84.2531C11.1766 84.4331 11.1726 84.6023 11.1552 84.7607L10.0355 84.9433C10.066 84.7943 10.0805 84.6364 10.0792 84.4695C10.0747 84.3003 10.0605 84.1427 10.0367 83.9968C10.0056 83.806 9.94815 83.6569 9.86438 83.5496C9.78062 83.4422 9.6783 83.3624 9.55742 83.3101C9.43653 83.2578 9.30486 83.2188 9.16238 83.1931L5.09015 86.0438L4.86496 84.6632L7.34194 83.0795C7.39394 83.0451 7.46444 83.0004 7.55344 82.9456C7.63918 82.8884 7.72795 82.8322 7.81975 82.7768C7.9083 82.7192 7.98722 82.6732 8.05651 82.6388L8.04896 82.5925C7.97515 82.5815 7.88616 82.5658 7.78199 82.5453C7.67457 82.5225 7.56551 82.4986 7.45481 82.4734C7.34132 82.4487 7.23973 82.4264 7.15005 82.4064L4.39124 81.7588L4.17154 80.4119Z" fill="#E50046"/>
            <path d="M4.87505 76.3071C4.89358 76.5595 4.95965 76.7798 5.07326 76.9682C5.18382 77.1539 5.33616 77.3067 5.53025 77.4264C5.72414 77.5433 5.95402 77.6262 6.21989 77.6751C6.48576 77.724 6.78174 77.7365 7.10783 77.7126C7.54734 77.6803 7.91909 77.5988 8.22309 77.4682C8.52405 77.3349 8.74833 77.1474 8.89593 76.9056C9.04069 76.6641 9.09975 76.3618 9.0731 75.9989C9.05457 75.7465 9.00768 75.4962 8.93244 75.248C8.85699 74.997 8.75588 74.7264 8.6291 74.4364L9.73497 74.3552C9.86859 74.6219 9.96928 74.8868 10.0371 75.1498C10.1077 75.4126 10.1552 75.7099 10.1795 76.0416C10.2266 76.6825 10.1334 77.2196 9.90013 77.6529C9.66378 78.0837 9.3147 78.4158 8.8529 78.6492C8.38826 78.8829 7.83695 79.0232 7.19894 79.07C6.72824 79.1046 6.29255 79.0724 5.89187 78.9735C5.4912 78.8747 5.13861 78.7124 4.8341 78.4867C4.52959 78.2611 4.28604 77.9725 4.10343 77.621C3.92083 77.2695 3.81224 76.8584 3.77768 76.3877C3.75499 76.0786 3.77199 75.7666 3.82869 75.4517C3.88235 75.1342 3.96683 74.8286 4.08211 74.5351L5.18518 74.8817C5.09167 75.1224 5.01244 75.3634 4.94749 75.6048C4.88254 75.8461 4.85839 76.0803 4.87505 76.3071Z" fill="#E50046"/>
            <path d="M10.3414 64.8003L10.2449 66.1146L5.12383 65.7386L5.01202 67.2613C5.27607 67.3149 5.56401 67.3731 5.87584 67.4359C6.18767 67.4987 6.50224 67.5632 6.81953 67.6292C7.13683 67.6953 7.43832 67.763 7.72401 67.8325C8.0099 67.8991 8.26036 67.9631 8.47536 68.0245C8.8329 68.1249 9.13459 68.2482 9.38042 68.3946C9.62362 68.5379 9.80384 68.7236 9.92108 68.9517C10.0385 69.177 10.0845 69.464 10.0588 69.8128C10.0503 69.929 10.0336 70.0404 10.0087 70.1469C9.98393 70.2506 9.95407 70.3467 9.91905 70.4354L8.8387 70.3561C8.86963 70.2842 8.89631 70.2121 8.91874 70.1396C8.93833 70.0669 8.95135 69.9866 8.95781 69.8987C8.96614 69.7853 8.93614 69.689 8.86783 69.6099C8.79689 69.5277 8.67287 69.4502 8.49577 69.3773C8.31583 69.3042 8.06642 69.226 7.74754 69.1427C7.60763 69.1068 7.41022 69.0581 7.15531 68.9966C6.89756 68.9349 6.59628 68.8644 6.25146 68.7849C5.90381 68.7052 5.52507 68.6218 5.11524 68.5347C4.70562 68.4447 4.27878 68.3564 3.83471 68.2696L4.12297 64.3438L10.3414 64.8003Z" fill="#E50046"/>
            <path d="M11.17 59.6785L10.5783 63.2204L4.42842 62.1931L5.02007 58.6512L6.08852 58.8297L5.7147 61.0676L7.06499 61.2931L7.41282 59.2109L8.48127 59.3894L8.13345 61.4716L9.7193 61.7365L10.0931 59.4987L11.17 59.6785Z" fill="#E50046"/>
            <path d="M12.1011 55.8071L11.7747 57.0882L6.79881 55.8206L6.38082 57.4613L5.31456 57.1897L6.47692 52.6271L7.54318 52.8987L7.1252 54.5394L12.1011 55.8071Z" fill="#E50046"/>
            <path d="M14.3098 49.0359L12.7605 48.9552L11.9859 51.0923L13.2272 52.023L12.7418 53.3622L7.60562 49.16L8.15637 47.6404L14.7952 47.6967L14.3098 49.0359ZM11.6104 48.8741L10.0931 48.805C9.99256 48.7988 9.8632 48.7927 9.70506 48.7868C9.54522 48.7773 9.38355 48.7686 9.22006 48.7607C9.05487 48.7493 8.91263 48.737 8.79335 48.724C8.89326 48.7905 9.01602 48.8773 9.16164 48.9845C9.30555 49.0881 9.44193 49.1874 9.57078 49.2825C9.69962 49.3776 9.79139 49.4456 9.84609 49.4866L11.0568 50.4017L11.6104 48.8741Z" fill="#E50046"/>
            <path d="M14.0363 35.7023L15.1078 34.0845C15.5662 33.3923 16.0104 32.9328 16.4403 32.706C16.8701 32.4792 17.3292 32.5275 17.8175 32.8509C18.0143 32.9812 18.1709 33.131 18.2873 33.3002C18.4053 33.467 18.476 33.6434 18.4994 33.8294C18.5228 34.0155 18.4934 34.204 18.4112 34.3951L18.4468 34.4186C18.5969 34.2692 18.7622 34.1535 18.9425 34.0718C19.1205 33.9884 19.3199 33.9602 19.5408 33.9872C19.7609 34.0102 20.0072 34.1119 20.2798 34.2925C20.5951 34.5013 20.8143 34.7573 20.9374 35.0605C21.0606 35.3638 21.0876 35.6988 21.0185 36.0657C20.9486 36.4287 20.7834 36.8069 20.5228 37.2004L19.2346 39.1453L14.0363 35.7023ZM16.825 35.9636L17.2489 35.3236C17.4609 35.0036 17.5579 34.749 17.54 34.5598C17.5197 34.369 17.4112 34.2085 17.2144 34.0781C17.0153 33.9463 16.8244 33.9255 16.6417 34.016C16.4583 34.1025 16.2621 34.3034 16.0533 34.6186L15.6694 35.1982L16.825 35.9636ZM17.6997 36.5429L19.0544 37.4402L19.5301 36.7219C19.7499 36.3901 19.839 36.1166 19.7973 35.9014C19.7557 35.6863 19.627 35.5073 19.4113 35.3644C19.2833 35.2796 19.1518 35.2335 19.017 35.226C18.8821 35.2185 18.7427 35.2643 18.5987 35.3633C18.4562 35.4599 18.3073 35.6256 18.1519 35.8602L17.6997 36.5429Z" fill="#E50046"/>
            <path d="M22.3794 34.4323L17.5741 30.4592L18.4165 29.4402L20.2604 30.9648L20.5322 30.6361C20.9 30.1913 21.2641 29.8781 21.6245 29.6965C21.9867 29.5127 22.3356 29.4433 22.671 29.4882C23.0043 29.5314 23.3123 29.6698 23.5949 29.9035C24.0704 30.2966 24.3092 30.7597 24.3113 31.2926C24.3151 31.8234 24.0335 32.4317 23.4665 33.1175L22.3794 34.4323ZM22.387 32.7231L22.6072 32.4568C22.8554 32.1566 23.0017 31.8792 23.0462 31.6246C23.0886 31.3681 22.987 31.1385 22.7416 30.9356C22.5707 30.7942 22.4077 30.7259 22.2526 30.7305C22.0976 30.7351 21.9411 30.7994 21.7832 30.9234C21.6254 31.0474 21.4567 31.2179 21.2774 31.4348L21.0953 31.6551L22.387 32.7231ZM25.5807 30.5604L20.7754 26.5873L21.6151 25.5716L26.4205 29.5447L25.5807 30.5604Z" fill="#E50046"/>
            <path d="M27.4909 28.4722L23.1303 24.0155L25.947 21.2595L26.7106 22.0399L24.8389 23.8713L25.7486 24.801L26.1205 24.4371C26.5391 24.0275 26.9392 23.7494 27.3208 23.6028C27.7044 23.4541 28.0607 23.4198 28.3896 23.4998C28.7185 23.5758 29.0112 23.7448 29.2677 24.007C29.6992 24.448 29.8869 24.9346 29.8308 25.4668C29.7768 25.997 29.4297 26.5752 28.7895 27.2016L27.4909 28.4722ZM27.6783 26.7733L27.9953 26.4631C28.2819 26.1827 28.4627 25.9163 28.5379 25.6638C28.611 25.4092 28.5362 25.1682 28.3135 24.9405C28.1584 24.782 27.9995 24.7009 27.8367 24.6971C27.6759 24.6913 27.5054 24.7468 27.3252 24.8634C27.147 24.9781 26.9543 25.1368 26.747 25.3397L26.5062 25.5753L27.6783 26.7733Z" fill="#E50046"/>
            <path d="M29.2132 18.4552C29.8352 17.9448 30.399 17.7064 30.9048 17.74C31.4088 17.7715 31.8529 18.0212 32.2371 18.4894C32.4102 18.7004 32.5443 18.9287 32.6393 19.1744C32.7325 19.418 32.7679 19.674 32.7455 19.9425C32.7252 20.2091 32.6314 20.4848 32.464 20.7693C32.2948 21.0516 32.0344 21.337 31.6827 21.6256L31.2443 21.9855L32.6512 23.6998L31.6292 24.5385L27.6736 19.7187L29.2132 18.4552ZM29.8477 19.3359L29.3828 19.7174L30.5571 21.1481L30.8933 20.8722C31.0845 20.7152 31.2297 20.5538 31.3289 20.3878C31.4281 20.2218 31.4719 20.0534 31.4605 19.8826C31.449 19.7117 31.3738 19.5417 31.2349 19.3725C31.0401 19.1351 30.8299 19.0134 30.6042 19.0074C30.3768 18.9991 30.1246 19.1086 29.8477 19.3359Z" fill="#E50046"/>
            <path d="M39.4476 18.7339L38.2475 17.7506L36.357 19.0127L36.8051 20.4981L35.6204 21.289L33.9744 14.8601L35.3187 13.9627L40.6322 17.943L39.4476 18.7339ZM37.3694 17.0036L36.1883 16.0486C36.1109 15.984 36.0103 15.9025 35.8865 15.804C35.7634 15.7016 35.6383 15.5987 35.5113 15.4955C35.3851 15.3883 35.2778 15.2942 35.1894 15.213C35.2305 15.3258 35.2779 15.4685 35.3316 15.6411C35.3861 15.8098 35.4371 15.9707 35.4845 16.1236C35.5319 16.2766 35.5655 16.3858 35.5853 16.4512L36.018 17.9058L37.3694 17.0036Z" fill="#E50046"/>
            <path d="M44.0471 16.1222L42.8802 16.7436L40.4667 12.2113L38.9722 13.0071L38.4551 12.0359L42.6109 9.82289L43.1281 10.7941L41.6336 11.5899L44.0471 16.1222Z" fill="#E50046"/>
            <path d="M46.8313 14.736L44.4239 8.98443L45.6434 8.47396L46.5672 10.681L47.0472 10.4801C47.5875 10.2539 48.062 10.1432 48.4708 10.1478C48.8822 10.1512 49.2259 10.2509 49.502 10.4466C49.7796 10.6386 49.9893 10.9038 50.1309 11.2421C50.3691 11.8112 50.3639 12.3327 50.1154 12.8067C49.8694 13.2795 49.3334 13.6888 48.5072 14.0346L46.8313 14.736ZM47.6326 13.2263L48.0418 13.0551C48.4116 12.9003 48.678 12.7194 48.841 12.5125C49.003 12.3029 49.0225 12.0513 48.8995 11.7575C48.8139 11.5529 48.6961 11.4188 48.5462 11.3552C48.3989 11.2905 48.22 11.2791 48.0094 11.321C47.8015 11.3618 47.5638 11.4382 47.2963 11.5501L46.9855 11.6802L47.6326 13.2263Z" fill="#E50046"/>
            <path d="M56.0003 6.00868C55.7558 6.07393 55.5516 6.17993 55.3877 6.32667C55.2259 6.46993 55.1042 6.64807 55.0227 6.86109C54.944 7.07338 54.9055 7.31468 54.907 7.58501C54.9085 7.85533 54.9514 8.14844 55.0358 8.46435C55.1494 8.89014 55.2988 9.24019 55.4838 9.5145C55.6709 9.78532 55.8969 9.97071 56.162 10.0706C56.4263 10.1678 56.7342 10.1695 57.0858 10.0756C57.3303 10.0104 57.5675 9.91765 57.7973 9.79745C58.0299 9.67652 58.2768 9.52673 58.5381 9.3481L58.8241 10.4194C58.5869 10.6004 58.3455 10.7488 58.0997 10.8644C57.8547 10.9828 57.5715 11.0848 57.2501 11.1706C56.6293 11.3363 56.0842 11.345 55.615 11.1966C55.1477 11.0447 54.7564 10.7636 54.4409 10.3535C54.1247 9.94055 53.8841 9.42505 53.7191 8.80697C53.5974 8.35096 53.5478 7.91692 53.5702 7.50484C53.5926 7.09275 53.6863 6.71609 53.8512 6.37485C54.0162 6.0336 54.2543 5.74051 54.5656 5.49557C54.8768 5.25063 55.2605 5.0673 55.7165 4.94558C56.0159 4.86565 56.3256 4.82419 56.6456 4.82118C56.9675 4.81469 57.2835 4.84072 57.5934 4.89925L57.4585 6.04761C57.2046 6.0006 56.9531 5.9677 56.7038 5.94889C56.4545 5.93009 56.22 5.95002 56.0003 6.00868Z" fill="#E50046"/>
            <path d="M59.1342 4.22321L61.0469 3.89617C61.8653 3.75624 62.5043 3.76525 62.9639 3.92318C63.4236 4.08111 63.7028 4.44873 63.8015 5.02605C63.8413 5.25866 63.8394 5.47532 63.7958 5.67603C63.7551 5.87626 63.6749 6.0486 63.5554 6.19307C63.4359 6.33754 63.2783 6.44524 63.0828 6.51617L63.09 6.55821C63.3017 6.56527 63.4992 6.60651 63.6823 6.68193C63.8648 6.75455 64.022 6.88056 64.1536 7.05997C64.2876 7.23609 64.3821 7.4853 64.4372 7.80759C64.5009 8.18032 64.4645 8.51539 64.3278 8.81277C64.1912 9.11016 63.9655 9.35931 63.6509 9.56022C63.3385 9.75785 62.9497 9.89643 62.4845 9.97598L60.185 10.3691L59.1342 4.22321ZM60.8535 6.43438L61.6102 6.305C61.9886 6.24031 62.2405 6.13666 62.3661 5.99403C62.4913 5.84861 62.5339 5.65959 62.4942 5.42698C62.4539 5.19157 62.3383 5.03827 62.1473 4.96709C61.9586 4.89262 61.6779 4.88726 61.3052 4.95099L60.6199 5.06815L60.8535 6.43438ZM61.0304 7.46851L61.3042 9.07016L62.1534 8.92496C62.5457 8.85788 62.806 8.73549 62.9343 8.5578C63.0626 8.38011 63.1049 8.16375 63.0613 7.90872C63.0354 7.75738 62.979 7.63001 62.8921 7.52661C62.8052 7.4232 62.6764 7.35293 62.5056 7.31579C62.3376 7.27816 62.1149 7.28307 61.8375 7.33051L61.0304 7.46851Z" fill="#E50046"/>
            <path d="M71.2853 5.9361C71.3187 6.41544 71.2895 6.85638 71.1978 7.25892C71.1059 7.65862 70.9478 8.00877 70.7236 8.30937C70.5023 8.60977 70.2111 8.84947 69.8502 9.02848C69.4891 9.20465 69.0547 9.31039 68.547 9.3457C68.0393 9.38101 67.5944 9.33643 67.2124 9.21194C66.8302 9.08462 66.5073 8.88763 66.2436 8.62096C65.9828 8.3541 65.7791 8.02768 65.6326 7.64171C65.486 7.25575 65.396 6.82167 65.3624 6.3395C65.3177 5.69565 65.3836 5.12817 65.5604 4.63706C65.7398 4.14293 66.0401 3.74868 66.4614 3.45432C66.8827 3.15996 67.4323 2.98921 68.1102 2.94206C68.7852 2.89511 69.3489 2.98843 69.8012 3.22203C70.2563 3.45543 70.6055 3.8045 70.8487 4.26925C71.0948 4.73379 71.2403 5.28941 71.2853 5.9361ZM66.7457 6.25183C66.7759 6.68579 66.8559 7.05644 66.9855 7.36378C67.1179 7.66809 67.3062 7.89724 67.5506 8.05124C67.7949 8.20241 68.1013 8.26517 68.4701 8.23953C68.8445 8.21349 69.1421 8.10871 69.3631 7.92519C69.5838 7.73883 69.7358 7.486 69.819 7.1667C69.9049 6.84437 69.9328 6.46623 69.9026 6.03227C69.8572 5.37991 69.6996 4.87501 69.4296 4.51758C69.1596 4.16015 68.7453 4.00086 68.1865 4.03973C67.8149 4.06557 67.5174 4.17177 67.2938 4.35832C67.0728 4.54184 66.918 4.79487 66.8293 5.1174C66.7432 5.43689 66.7154 5.81504 66.7457 6.25183Z" fill="#E50046"/>
            <path d="M73.4245 2.81697L74.6131 2.87205L74.4737 5.87977C74.4669 6.02746 74.4565 6.19063 74.4425 6.3693C74.4314 6.5481 74.4175 6.72392 74.401 6.89678C74.3874 7.06692 74.3748 7.21577 74.3632 7.34331C74.3516 7.47085 74.342 7.5558 74.3343 7.59813L74.3599 7.59932L77.4547 3.00373L79.0352 3.07698L78.7466 9.30542L77.5708 9.25093L77.7093 6.26026C77.7167 6.10121 77.7276 5.92809 77.7419 5.74091C77.7563 5.55372 77.7718 5.37228 77.7885 5.19658C77.808 5.02102 77.825 4.86953 77.8395 4.74212C77.854 4.61187 77.8637 4.52551 77.8686 4.48304L77.8345 4.48146L74.7249 9.11905L73.1359 9.04542L73.4245 2.81697ZM78.538 1.00037C78.4928 1.29998 78.3953 1.56158 78.2455 1.78518C78.0956 2.00879 77.8701 2.17765 77.5688 2.29177C77.2675 2.40589 76.8669 2.45136 76.367 2.4282C75.853 2.40438 75.4553 2.32475 75.1741 2.18934C74.8931 2.05108 74.6967 1.86551 74.5851 1.63264C74.4737 1.39693 74.4124 1.12227 74.4013 0.80867L75.5644 0.862567C75.575 1.18469 75.6461 1.40145 75.7776 1.51285C75.9092 1.62142 76.1255 1.68267 76.4265 1.69662C76.6765 1.70821 76.8836 1.66088 77.0479 1.55464C77.2122 1.44841 77.317 1.24549 77.3622 0.94588L78.538 1.00037Z" fill="#E50046"/>
            <path d="M84.4068 3.77653L86.3101 4.15466C87.1244 4.31643 87.718 4.55321 88.0908 4.86499C88.4637 5.17676 88.5931 5.61989 88.479 6.19436C88.433 6.42582 88.3538 6.62749 88.2414 6.79938C88.1317 6.97181 87.9953 7.10414 87.832 7.19635C87.6688 7.28856 87.4832 7.33285 87.2752 7.32922L87.2669 7.37105C87.4621 7.45332 87.6318 7.56239 87.7758 7.69826C87.9204 7.83134 88.0221 8.00518 88.0809 8.21978C88.1431 8.43215 88.1423 8.69868 88.0786 9.01938C88.0049 9.39028 87.8511 9.69018 87.6172 9.9191C87.3833 10.148 87.0835 10.3001 86.7179 10.3752C86.3555 10.4482 85.9429 10.4387 85.4799 10.3467L83.1918 9.89214L84.4068 3.77653ZM85.2224 6.45614L85.9753 6.60573C86.3518 6.68052 86.6242 6.67376 86.7925 6.58544C86.9613 6.49434 87.0687 6.33305 87.1147 6.10159C87.1612 5.86734 87.108 5.68285 86.9551 5.5481C86.8054 5.41113 86.5452 5.30579 86.1743 5.23211L85.4925 5.09665L85.2224 6.45614ZM85.0179 7.48517L84.7013 9.07891L85.5463 9.24678C85.9367 9.32434 86.2236 9.30306 86.4069 9.18294C86.5902 9.06283 86.707 8.87588 86.7574 8.62211C86.7873 8.47152 86.7802 8.33241 86.736 8.20478C86.6918 8.07714 86.5966 7.96547 86.4503 7.86975C86.3069 7.77458 86.0972 7.69957 85.8211 7.64473L85.0179 7.48517Z" fill="#E50046"/>
            <path d="M93.638 12.5013L92.1984 12.0759L90.9264 8.40458L90.0307 11.4352L88.7628 11.0605L90.5301 5.08104L91.7979 5.45576L90.9421 8.35141L93.941 6.08915L95.2907 6.48805L92.2622 8.71489L93.638 12.5013Z" fill="#E50046"/>
            <path d="M101.111 8.76481L97.6891 12.1513C97.3811 12.4573 97.083 12.7076 96.7947 12.9022C96.5075 13.0941 96.1955 13.2059 95.8588 13.2374C95.5231 13.2663 95.1279 13.1906 94.6734 13.0103C94.5307 12.9537 94.3803 12.8817 94.2222 12.7945C94.063 12.71 93.9199 12.6196 93.7929 12.5233L94.2113 11.4688C94.3235 11.5715 94.4516 11.6651 94.5954 11.7497C94.7429 11.8327 94.8854 11.9015 95.0228 11.956C95.2026 12.0273 95.36 12.0546 95.4951 12.0378C95.6302 12.0211 95.7512 11.9743 95.8582 11.8974C95.9652 11.8206 96.0663 11.7276 96.1615 11.6186L95.8071 6.66037L97.1074 7.17626L97.194 10.115C97.1968 10.1773 97.1989 10.2607 97.2002 10.3652C97.2053 10.4681 97.2079 10.5732 97.2082 10.6804C97.2122 10.786 97.2112 10.8773 97.2051 10.9544L97.2487 10.9717C97.296 10.914 97.3552 10.8458 97.4263 10.7669C97.501 10.6864 97.5776 10.6052 97.6561 10.5231C97.7355 10.4384 97.8069 10.3627 97.87 10.296L99.8426 8.26151L101.111 8.76481Z" fill="#E50046"/>
            <path d="M104.413 11.538C104.187 11.4233 103.964 11.3657 103.744 11.3654C103.528 11.3639 103.319 11.4151 103.116 11.5192C102.915 11.6246 102.726 11.7783 102.546 11.9805C102.367 12.1827 102.203 12.4294 102.054 12.7208C101.854 13.1136 101.732 13.4738 101.686 13.8016C101.644 14.1281 101.689 14.417 101.819 14.6684C101.951 14.9173 102.179 15.1243 102.503 15.2894C102.729 15.4042 102.967 15.4936 103.219 15.5577C103.472 15.6231 103.756 15.6767 104.07 15.7183L103.567 16.7065C103.27 16.6828 102.991 16.6318 102.731 16.5537C102.469 16.4781 102.19 16.3648 101.894 16.2139C101.321 15.9224 100.91 15.5647 100.66 15.1408C100.414 14.7156 100.31 14.245 100.35 13.729C100.39 13.2105 100.556 12.6662 100.846 12.0961C101.06 11.6755 101.313 11.3194 101.605 11.0277C101.897 10.7361 102.218 10.5184 102.569 10.3747C102.92 10.231 103.293 10.172 103.688 10.1977C104.083 10.2234 104.491 10.3433 104.912 10.5574C105.188 10.698 105.446 10.8741 105.686 11.0856C105.93 11.2959 106.148 11.5263 106.339 11.7769L105.472 12.5412C105.314 12.3367 105.149 12.1441 104.976 11.9636C104.803 11.7831 104.615 11.6412 104.413 11.538Z" fill="#E50046"/>
            <path d="M111.187 21.956L110.11 21.1964L113.069 17.0003L111.821 16.1203C111.64 16.3191 111.441 16.5359 111.227 16.7709C111.013 17.0058 110.795 17.2423 110.575 17.4803C110.355 17.7182 110.142 17.9418 109.935 18.1509C109.731 18.3617 109.547 18.5434 109.384 18.6961C109.114 18.9509 108.852 19.146 108.6 19.2813C108.352 19.416 108.1 19.4749 107.844 19.4579C107.591 19.4425 107.321 19.3341 107.035 19.1325C106.94 19.0653 106.853 18.9936 106.775 18.9174C106.699 18.8428 106.632 18.7677 106.574 18.6921L107.198 17.8068C107.244 17.8703 107.292 17.9304 107.342 17.9869C107.395 18.0411 107.457 18.0937 107.529 18.1445C107.622 18.21 107.72 18.2339 107.823 18.2161C107.93 18.1976 108.06 18.1312 108.214 18.0169C108.369 17.9003 108.564 17.7268 108.8 17.4963C108.903 17.3949 109.046 17.2508 109.23 17.0639C109.416 16.8747 109.632 16.6528 109.877 16.3982C110.125 16.1412 110.391 15.8595 110.677 15.553C110.965 15.2482 111.26 14.9278 111.563 14.5919L114.78 16.8607L111.187 21.956Z" fill="#E50046"/>
            <path d="M115.213 25.3239L112.479 22.9957L116.521 18.2487L119.255 20.5769L118.553 21.4016L116.826 19.9306L115.938 20.9729L117.545 22.3416L116.843 23.1663L115.236 21.7976L114.193 23.0217L115.921 24.4927L115.213 25.3239Z" fill="#E50046"/>
            <path d="M118.122 28.1605L117.191 27.2222L120.835 23.6046L119.642 22.403L120.423 21.6278L123.74 24.9692L122.959 25.7444L121.766 24.5428L118.122 28.1605Z" fill="#E50046"/>
            <path d="M122.828 33.5497L123.693 32.2619L122.258 30.4995L120.822 31.0864L119.922 29.982L126.166 27.734L127.187 28.9872L123.728 34.6541L122.828 33.5497ZM124.354 31.3169L125.192 30.0506C125.249 29.9675 125.321 29.8596 125.407 29.7269C125.498 29.5947 125.588 29.4605 125.679 29.3242C125.774 29.1884 125.857 29.0726 125.93 28.977C125.821 29.0286 125.684 29.0893 125.517 29.1591C125.354 29.2294 125.199 29.2954 125.051 29.3571C124.903 29.4188 124.798 29.4626 124.735 29.4884L123.328 30.0571L124.354 31.3169Z" fill="#E50046"/>
            <path d="M132.781 37.1228L133.706 38.8288C134.101 39.5587 134.298 40.1666 134.297 40.6527C134.295 41.1387 134.036 41.5213 133.522 41.8004C133.314 41.9129 133.108 41.9807 132.904 42.004C132.702 42.0298 132.513 42.0093 132.337 41.9426C132.162 41.8759 132.009 41.7614 131.879 41.599L131.842 41.6193C131.903 41.8221 131.928 42.0223 131.915 42.2199C131.905 42.4162 131.836 42.6055 131.709 42.7878C131.585 42.9713 131.38 43.1409 131.092 43.2967C130.76 43.4769 130.431 43.5502 130.105 43.5164C129.78 43.4826 129.471 43.3491 129.18 43.1157C128.892 42.8835 128.636 42.5599 128.411 42.145L127.299 40.0941L132.781 37.1228ZM131.24 39.4618L131.606 40.1367C131.789 40.4742 131.968 40.6795 132.143 40.7525C132.321 40.8242 132.514 40.8039 132.722 40.6914C132.931 40.5776 133.039 40.4188 133.045 40.2151C133.055 40.0125 132.97 39.7449 132.79 39.4125L132.459 38.8013L131.24 39.4618ZM130.318 39.9618L128.889 40.7361L129.3 41.4935C129.489 41.8434 129.689 42.0506 129.898 42.1149C130.108 42.1792 130.326 42.1497 130.554 42.0264C130.689 41.9533 130.791 41.8589 130.861 41.7434C130.931 41.6278 130.956 41.4832 130.937 41.3096C130.918 41.1384 130.842 40.9291 130.708 40.6817L130.318 39.9618Z" fill="#E50046"/>
            <path d="M130.056 45.2038L135.825 42.8394L136.327 44.0628L134.113 44.97L134.274 45.3647C134.493 45.8987 134.598 46.3675 134.588 46.771C134.579 47.177 134.476 47.5174 134.278 47.7921C134.083 48.0656 133.816 48.2719 133.477 48.411C132.906 48.645 132.385 48.6372 131.914 48.3878C131.444 48.1409 131.04 47.6058 130.703 46.7823L130.056 45.2038ZM131.56 46.0164L131.691 46.336C131.838 46.6965 132.014 46.9563 132.217 47.1156C132.424 47.2739 132.674 47.2926 132.969 47.1719C133.174 47.0878 133.311 46.9763 133.38 46.8374C133.449 46.6985 133.466 46.5301 133.431 46.3324C133.396 46.1347 133.326 45.9057 133.219 45.6452L133.11 45.3808L131.56 46.0164ZM131.961 49.8525L137.73 47.4881L138.23 48.7075L132.461 51.0719L131.961 49.8525Z" fill="#E50046"/>
            <path d="M132.852 52.3297L138.838 50.5851L139.941 54.3684L138.893 54.6739L138.16 52.1599L136.911 52.5238L137.057 53.0233C137.221 53.5856 137.278 54.0696 137.227 54.4753C137.177 54.8837 137.039 55.214 136.814 55.4663C136.592 55.7206 136.305 55.899 135.953 56.0016C135.36 56.1743 134.843 56.1104 134.4 55.8101C133.958 55.5125 133.611 54.9338 133.361 54.0739L132.852 52.3297ZM134.262 53.2959L134.386 53.7217C134.499 54.1066 134.648 54.3917 134.836 54.577C135.026 54.7615 135.273 54.8092 135.579 54.7201C135.792 54.658 135.939 54.5561 136.019 54.4143C136.1 54.2752 136.131 54.0987 136.113 53.8848C136.096 53.6736 136.047 53.4288 135.966 53.1504L135.871 52.8269L134.262 53.2959Z" fill="#E50046"/>
            <path d="M140.899 58.6199C141.053 59.4097 140.995 60.0191 140.725 60.4482C140.458 60.8768 140.028 61.1489 139.433 61.2646C139.165 61.3168 138.901 61.3263 138.639 61.2932C138.381 61.2595 138.139 61.1691 137.913 61.0219C137.688 60.8774 137.49 60.6639 137.319 60.3815C137.151 60.0985 137.023 59.7337 136.936 59.2872L136.828 58.7304L134.651 59.1543L134.399 57.8565L140.519 56.6649L140.899 58.6199ZM139.823 58.7599L139.708 58.1697L137.891 58.5234L137.974 58.9504C138.022 59.1932 138.095 59.3976 138.194 59.5638C138.293 59.7299 138.42 59.8485 138.576 59.9195C138.732 59.9906 138.917 60.0052 139.132 59.9633C139.433 59.9046 139.64 59.7775 139.753 59.5817C139.868 59.3855 139.892 59.1116 139.823 58.7599Z" fill="#E50046"/>
            <path d="M135.838 67.6433L137.274 67.056L137.064 64.7926L135.545 64.4796L135.413 63.0613L141.85 64.6739L142 66.2834L135.97 69.0617L135.838 67.6433ZM138.349 66.6393L139.751 66.0552C139.845 66.0179 139.964 65.9683 140.11 65.9062C140.259 65.8467 140.409 65.7857 140.56 65.7231C140.714 65.6631 140.848 65.6136 140.962 65.5745C140.843 65.5569 140.695 65.5307 140.517 65.4958C140.343 65.4634 140.177 65.4317 140.02 65.4006C139.863 65.3695 139.751 65.347 139.684 65.3332L138.199 65.0214L138.349 66.6393Z" fill="#E50046"/>
            <path d="M135.972 73.0658L135.982 71.7438L141.117 71.7834L141.13 70.0903L142.23 70.0988L142.194 74.807L141.094 74.7985L141.107 73.1055L135.972 73.0658Z" fill="#E50046"/>
            <path d="M135.861 76.4736L142.065 77.0976L141.932 78.4131L139.552 78.1736L139.5 78.6913C139.441 79.2741 139.312 79.7441 139.114 80.1013C138.915 80.4613 138.663 80.716 138.359 80.8655C138.058 81.0181 137.725 81.0761 137.36 81.0394C136.747 80.9776 136.291 80.7246 135.992 80.2802C135.694 79.8387 135.589 79.1724 135.679 78.2813L135.861 76.4736ZM136.806 77.8975L136.762 78.3388C136.722 78.7377 136.754 79.0581 136.858 79.3C136.965 79.5423 137.177 79.6793 137.494 79.7112C137.715 79.7334 137.889 79.6937 138.016 79.5922C138.143 79.4936 138.238 79.3417 138.302 79.1366C138.365 78.9344 138.411 78.689 138.44 78.4005L138.474 78.0652L136.806 77.8975Z" fill="#E50046"/>
            <path d="M139.236 88.4378C139.295 88.1916 139.298 87.9616 139.247 87.7477C139.198 87.5372 139.099 87.3455 138.95 87.1726C138.801 87.0025 138.607 86.8539 138.368 86.7268C138.13 86.5998 137.851 86.4983 137.533 86.4224C137.105 86.3201 136.726 86.2852 136.396 86.3177C136.069 86.3536 135.799 86.4644 135.585 86.6502C135.374 86.8366 135.226 87.1068 135.141 87.4608C135.083 87.7069 135.052 87.9596 135.048 88.219C135.044 88.4811 135.059 88.7695 135.092 89.0843L134.013 88.8269C133.966 88.5323 133.951 88.2494 133.966 87.9782C133.978 87.7063 134.023 87.4086 134.1 87.0851C134.249 86.4601 134.5 85.9763 134.854 85.6339C135.21 85.295 135.643 85.0841 136.154 85.0014C136.667 84.9193 137.235 84.9526 137.857 85.101C138.316 85.2106 138.722 85.3731 139.074 85.5886C139.426 85.8042 139.713 86.0655 139.935 86.3728C140.157 86.6801 140.301 87.0288 140.369 87.4191C140.437 87.8094 140.416 88.2341 140.306 88.6932C140.234 88.9946 140.124 89.2868 139.974 89.5698C139.827 89.8562 139.654 90.1218 139.455 90.3667L138.509 89.7024C138.671 89.5014 138.819 89.2956 138.954 89.0852C139.089 88.8748 139.183 88.659 139.236 88.4378Z" fill="#E50046"/>
            <path d="M139.212 92.4427L138.585 94.2791C138.317 95.0649 138.004 95.6219 137.646 95.9502C137.287 96.2785 136.831 96.3481 136.276 96.1589C136.053 96.0827 135.864 95.9775 135.708 95.8433C135.552 95.7118 135.439 95.5591 135.369 95.385C135.299 95.211 135.28 95.0211 135.311 94.8155L135.271 94.8017C135.163 94.9843 135.033 95.1381 134.879 95.2628C134.728 95.3885 134.542 95.4663 134.322 95.4962C134.103 95.5297 133.839 95.4937 133.529 95.3881C133.171 95.2659 132.895 95.0738 132.699 94.8116C132.503 94.5495 132.392 94.2322 132.366 93.8598C132.341 93.491 132.405 93.0832 132.558 92.6366L133.311 90.4288L139.212 92.4427ZM136.448 92.8963L136.2 93.6228C136.076 93.9861 136.047 94.257 136.112 94.4355C136.18 94.6149 136.326 94.7427 136.549 94.8189C136.775 94.8961 136.965 94.8677 137.119 94.734C137.274 94.6038 137.413 94.3598 137.535 94.0019L137.76 93.344L136.448 92.8963ZM135.455 92.5575L133.917 92.0326L133.639 92.8479C133.511 93.2246 133.494 93.5118 133.589 93.7094C133.683 93.907 133.853 94.0475 134.098 94.1311C134.243 94.1807 134.382 94.192 134.514 94.1651C134.647 94.1382 134.77 94.0586 134.884 93.9263C134.998 93.7968 135.1 93.5988 135.191 93.3324L135.455 92.5575Z" fill="#E50046"/>
            <path d="M132.036 102.02C131.599 101.821 131.225 101.586 130.914 101.313C130.607 101.043 130.374 100.737 130.216 100.397C130.057 100.059 129.985 99.689 129.999 99.2864C130.016 98.885 130.129 98.4526 130.34 97.9892C130.55 97.5258 130.801 97.1557 131.092 96.8788C131.386 96.6032 131.713 96.4128 132.073 96.3077C132.431 96.2052 132.815 96.1813 133.225 96.236C133.634 96.2907 134.059 96.4179 134.499 96.6178C135.086 96.8846 135.554 97.2126 135.902 97.6016C136.251 97.9945 136.455 98.4462 136.514 98.9568C136.572 99.4673 136.461 100.032 136.18 100.651C135.901 101.267 135.55 101.718 135.13 102.005C134.708 102.294 134.235 102.436 133.71 102.428C133.185 102.424 132.627 102.288 132.036 102.02ZM133.918 97.8763C133.522 97.6964 133.158 97.5904 132.826 97.5583C132.495 97.53 132.204 97.5867 131.952 97.7284C131.703 97.8714 131.502 98.1111 131.349 98.4476C131.194 98.7894 131.145 99.101 131.201 99.3827C131.26 99.6655 131.41 99.9194 131.651 100.145C131.894 100.373 132.213 100.578 132.609 100.758C133.205 101.028 133.724 101.129 134.167 101.062C134.61 100.995 134.947 100.706 135.178 100.196C135.332 99.8567 135.381 99.5444 135.323 99.259C135.266 98.9773 135.118 98.7208 134.876 98.4894C134.636 98.2617 134.317 98.0573 133.918 97.8763Z" fill="#E50046"/>
            <path d="M133.848 105.247L133.235 106.267L130.654 104.717C130.527 104.641 130.388 104.554 130.238 104.457C130.086 104.362 129.938 104.267 129.793 104.17C129.65 104.077 129.525 103.996 129.418 103.925C129.311 103.854 129.241 103.805 129.208 103.779L129.195 103.8L131.77 108.706L130.955 110.062L125.61 106.851L126.217 105.842L128.783 107.384C128.92 107.466 129.067 107.558 129.225 107.659C129.383 107.761 129.535 107.861 129.682 107.959C129.827 108.059 129.952 108.146 130.057 108.219C130.165 108.294 130.237 108.344 130.272 108.368L130.289 108.339L127.684 103.4L128.503 102.037L133.848 105.247ZM133.019 110.61C132.777 110.428 132.593 110.218 132.467 109.98C132.342 109.742 132.3 109.464 132.343 109.144C132.385 108.825 132.535 108.451 132.793 108.022C133.058 107.581 133.317 107.268 133.57 107.085C133.825 106.904 134.081 106.819 134.339 106.831C134.599 106.845 134.87 106.921 135.152 107.061L134.552 108.059C134.263 107.915 134.039 107.875 133.878 107.938C133.72 108.002 133.564 108.163 133.409 108.421C133.28 108.636 133.223 108.841 133.239 109.036C133.254 109.231 133.383 109.419 133.625 109.601L133.019 110.61Z" fill="#E50046"/>
            <path d="M127.805 114.451L126.568 115.946C126.039 116.586 125.549 116.996 125.098 117.176C124.646 117.356 124.195 117.26 123.744 116.886C123.562 116.736 123.422 116.571 123.324 116.39C123.224 116.212 123.172 116.029 123.169 115.841C123.165 115.654 123.214 115.469 123.316 115.288L123.283 115.261C123.118 115.394 122.942 115.491 122.754 115.553C122.568 115.618 122.367 115.625 122.15 115.574C121.934 115.528 121.699 115.401 121.447 115.193C121.156 114.952 120.965 114.674 120.875 114.359C120.784 114.045 120.793 113.709 120.9 113.351C121.008 112.998 121.212 112.639 121.513 112.275L123 110.477L127.805 114.451ZM125.059 113.896L124.57 114.488C124.326 114.783 124.202 115.026 124.2 115.216C124.2 115.408 124.291 115.579 124.473 115.73C124.657 115.882 124.845 115.923 125.036 115.852C125.227 115.785 125.444 115.606 125.685 115.315L126.128 114.779L125.059 113.896ZM124.251 113.228L122.999 112.192L122.45 112.856C122.196 113.163 122.079 113.425 122.097 113.644C122.116 113.862 122.225 114.054 122.424 114.219C122.543 114.316 122.668 114.376 122.802 114.398C122.935 114.42 123.079 114.389 123.232 114.306C123.384 114.224 123.55 114.076 123.729 113.859L124.251 113.228Z" fill="#E50046"/>
            <path d="M115.679 118.515L116.737 117.449L120.572 118.072L118.329 115.845L119.261 114.907L123.685 119.3L122.754 120.238L120.611 118.111L121.18 121.824L120.188 122.823L119.665 119.1L115.679 118.515Z" fill="#E50046"/>
            <path d="M115.279 126.993L113.925 122.373C113.802 121.957 113.723 121.576 113.689 121.23C113.656 120.886 113.706 120.558 113.838 120.247C113.972 119.938 114.227 119.626 114.601 119.312C114.719 119.213 114.853 119.115 115.005 119.017C115.155 118.917 115.303 118.834 115.448 118.768L116.177 119.637C116.033 119.687 115.89 119.755 115.747 119.842C115.604 119.932 115.476 120.025 115.363 120.12C115.215 120.244 115.116 120.37 115.067 120.497C115.017 120.623 115.001 120.752 115.018 120.883C115.035 121.013 115.068 121.147 115.119 121.282L119.651 123.325L118.579 124.225L115.952 122.905C115.896 122.878 115.821 122.84 115.729 122.792C115.636 122.747 115.542 122.7 115.447 122.649C115.353 122.602 115.273 122.558 115.208 122.516L115.172 122.546C115.2 122.615 115.232 122.7 115.268 122.8C115.303 122.904 115.338 123.01 115.373 123.118C115.41 123.228 115.443 123.327 115.471 123.414L116.325 126.116L115.279 126.993Z" fill="#E50046"/>
            <path d="M111.333 128.54C111.541 128.396 111.697 128.227 111.801 128.033C111.905 127.843 111.959 127.635 111.963 127.407C111.965 127.18 111.92 126.94 111.826 126.686C111.733 126.433 111.593 126.171 111.407 125.903C111.156 125.541 110.896 125.262 110.629 125.067C110.361 124.876 110.086 124.778 109.803 124.774C109.521 124.772 109.231 124.875 108.932 125.083C108.724 125.227 108.532 125.395 108.357 125.586C108.179 125.778 107.998 126.003 107.813 126.26L107.18 125.349C107.342 125.098 107.519 124.877 107.711 124.685C107.901 124.49 108.133 124.298 108.406 124.108C108.934 123.742 109.443 123.549 109.935 123.529C110.426 123.513 110.89 123.645 111.326 123.924C111.763 124.205 112.164 124.608 112.529 125.134C112.799 125.521 112.992 125.913 113.111 126.308C113.23 126.703 113.27 127.09 113.23 127.467C113.191 127.843 113.066 128.2 112.856 128.536C112.647 128.872 112.348 129.175 111.96 129.444C111.706 129.621 111.429 129.765 111.129 129.876C110.828 129.991 110.522 130.074 110.211 130.124L109.948 128.998C110.203 128.956 110.45 128.902 110.691 128.835C110.932 128.768 111.146 128.67 111.333 128.54Z" fill="#E50046"/>
            <path d="M98.9219 129.586L100.101 128.997L102.396 133.591L103.762 132.908C103.673 132.654 103.576 132.377 103.47 132.076C103.365 131.776 103.259 131.473 103.154 131.167C103.048 130.86 102.952 130.566 102.866 130.285C102.777 130.006 102.704 129.758 102.647 129.541C102.55 129.183 102.502 128.861 102.502 128.574C102.501 128.292 102.568 128.042 102.704 127.825C102.838 127.609 103.061 127.423 103.374 127.266C103.478 127.214 103.583 127.172 103.687 127.139C103.789 127.107 103.886 127.083 103.981 127.068L104.465 128.037C104.387 128.047 104.312 128.061 104.238 128.079C104.165 128.099 104.09 128.129 104.011 128.169C103.909 128.219 103.842 128.294 103.809 128.394C103.774 128.497 103.771 128.643 103.799 128.832C103.828 129.024 103.888 129.279 103.98 129.595C104.021 129.734 104.08 129.928 104.157 130.179C104.236 130.432 104.329 130.727 104.437 131.064C104.547 131.403 104.669 131.772 104.803 132.168C104.935 132.566 105.078 132.978 105.23 133.404L101.709 135.164L98.9219 129.586Z" fill="#E50046"/>
            <path d="M94.2556 131.453L97.5984 130.141L99.8761 135.946L96.5333 137.257L96.1376 136.249L98.2497 135.42L97.7496 134.146L95.7844 134.917L95.3887 133.909L97.3538 133.137L96.7665 131.641L94.6544 132.47L94.2556 131.453Z" fill="#E50046"/>
            <path d="M90.2387 132.717L91.5066 132.343L92.962 137.267L94.5857 136.787L94.8975 137.842L90.3823 139.177L90.0704 138.121L91.6941 137.642L90.2387 132.717Z" fill="#E50046"/>
            <path d="M83.3051 134.285L84.0273 135.658L86.2601 135.232L86.4261 133.69L87.8254 133.423L86.8367 139.985L85.249 140.288L81.9059 134.552L83.3051 134.285ZM84.545 136.688L85.2608 138.028C85.3068 138.118 85.3677 138.232 85.4434 138.371C85.5168 138.513 85.592 138.657 85.6687 138.801C85.7432 138.949 85.8054 139.078 85.8552 139.187C85.8612 139.067 85.8732 138.917 85.891 138.737C85.9065 138.56 85.9222 138.392 85.9381 138.233C85.954 138.074 85.9656 137.96 85.9729 137.892L86.1411 136.384L84.545 136.688Z" fill="#E50046"/>
          </svg>
        </div>
        <div class="hero__button-center"></div>
      </div>
  </div>
</template>

<script>
  export default {
    name: "Loader",
    data() {
      return {
      }
    },
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"

.loader-overlay 
    width: 100vw
    height: 100vh
    overflow: hidden
    background: radial-gradient(#71D310, #3C7700)
    display: flex
    align-items: center
    justify-content: center
    z-index: 9999
    animation: colors
    animation-iteration-count: infinite
    animation-duration: 30s
.loader 
  border: vw(10px) solid rgba(255, 255, 255, 0.4)
  border-radius: 50%
  border-top: vw(10px) solid #E50046
  width: vw(120px)
  height: vw(120px)
  animation: spin 2s linear infinite
  &-item
    animation: spin
    animation-iteration-count: infinite
    animation-duration: 10s
    animation-timing-function: linear
    cursor: default !important
    top: 0
    bottom: 0
    margin: auto
    &:hover
      .hero__button-text svg path
        fill: rgb(229, 0, 70) !important
      .hero__button-center
        bottom: 0 !important
  @media screen and (max-width: 1000px)
    width: vwm(80px)
    height: vwm(80px)
    border-width: vwm(6px)
    border-top-width: vwm(6px)

@keyframes spin 
  0%
    transform: rotate(0deg)
  100% 
    transform: rotate(-360deg)
@keyframes colors 
  0%
    background: radial-gradient(#71D310, #3C7700)
  20% 
    background: radial-gradient(#71D310, #3C7700)
  40% 
    background: radial-gradient(#FF2532, #710F27)
  60% 
    background: radial-gradient(#FFBD80, #E25F00)
  80% 
    background: radial-gradient(#E8D52F, #FFA800)
  100% 
    background: radial-gradient(#71D310, #3C7700)
</style>