<template>
  <div class="page">
    <Loader v-if="loading" />
    <Hero @start="start" v-if="isShowHero && !loading" @show="setBoutle" />
    <Result v-if="isShowResult && !loading" :active="active" />
  </div>
</template>

<script>
  import Hero from '@/components/Hero'
  import Result from '@/components/Result'
  import Loader from '@/components/Loader'
  export default {
    name: "Main",
    data() {
      return {
        isShowHero: true,
        isShowResult: false,
        active: 0,
        loading: true,
        images: [
          require('@/assets/img/lemonade-1.png'),
          require('@/assets/img/lemonade-2.png'),
          require('@/assets/img/lemonade-3.png'),
          require('@/assets/img/lemonade-4.png'),
          require('@/assets/img/lemonade-5.png'),
          require('@/assets/img/lemonade-6.png'),
          require('@/assets/img/grass-1.png'),
          require('@/assets/img/grass-2.png'),
          require('@/assets/img/grass-2-mob.png'),
          require('@/assets/img/grass-1-mob.png'),
          require('@/assets/img/bg-hero.jpg'),
          require('@/assets/img/bg-hero-mobile.jpg'),
          require('@/assets/img/bottles-big.png'),
          require('@/assets/img/bottles-big-mob.jpg'),
          require('@/assets/img/bottles-big-mob.png'),
          require('@/assets/img/bottles-small.png'),
        ],
        loadedImages: [],
      }
    },
    components: {
      Hero,
      Result,
      Loader,
    },
    mounted() {
      this.preloadImages();
    },
    methods: {
      preloadImages() {
        const promises = this.images.map((src) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
              this.loadedImages.push(src);
              resolve();
            };
            img.onerror = reject;
          });
        });

        Promise.all(promises)
          .then(() => {
            setTimeout(() => {
              this.loading = false;
            }, 3000)
          })
          .catch((error) => {
            console.error('Error loading images:', error);
            setTimeout(() => {
              this.loading = false;
            }, 3000)
          });
      },
      setBoutle(item) {
        this.isShowResult = true;
        this.active = item;
      },
      start() {
        this.isShowHero = false;
        this.isShowRoulette = true;
      }
    },
  }
</script>