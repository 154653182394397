<template>
  <div class="header">
    <a href="https://miratorg.ru/products/catalog/napitki/?utm_source=afisha&utm_medium=specproekt&utm_campaign=MiratorgSummer24" target="_blank" class="header__logo header__logo-1" @click="sendEvent('logo_mirotorg')"></a>
    <a href="https://www.afisha.ru" target="_blank" class="header__logo header__logo-2" @click="sendEvent('logo_mirotorg')"></a>
  </div>
</template>

<script>
  export default {
	name: "Header",
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
.header
  display: flex
  justify-content: space-between
  align-items: center
  padding: vw(21px) vw(38px) 0 vw(35px) 
  @media screen and (max-width: 1000px)
    padding: vwm(10px) vwm(19px) 0 vwm(20px)
  &.color
    .header__logo-1 
      background-image: url(../assets/img/miratorg-hover.svg)
    .header__logo-2
      background-image: url(../assets/img/afisha-hover.svg)
  &__logo
    background-size: contain
    background-repeat: no-repeat
    display: block
    transition: all 300ms
    &-1 
      background-image: url(../assets/img/miratorg.svg)
      width: vw(136px)
      height: vw(20px)
      @media screen and (max-width: 1000px)
        width: vwm(105px)
        height: vwm(16px)
      &:hover 
        background-image: url(../assets/img/miratorg-hover.svg)
    &-2
      background-image: url(../assets/img/afisha.svg)
      width: vw(109px)
      height: vw(22px)
      @media screen and (max-width: 1000px) 
        width: vwm(86px)
        height: vwm(18px)
      &:hover 
        background-image: url(../assets/img/afisha-hover.svg)
</style>