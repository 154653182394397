<template>
  <div class="footer">
    <div class="footer__text">Реклама. Рекламодатель ООО «ТК «Мираторг»  erid: 2RanykSyL3r</div>
  </div>
</template>

<script>
  export default {
	name: "Footer",
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
.footer
  position: fixed
  bottom: vw(15px)
  left: vw(35px)
  z-index: 1000
  @media screen and (max-width: 1000px)
    bottom: vwm(12px) 
    left: 0
    width: 100%
  &.grey 
    .footer__text 
      color: #C5C2C2
  &__text 
    font-size: vw(10px)
    color: #fff
    @media screen and (max-width: 1000px) 
      font-size: vwm(10px)
      text-align: center
</style>