import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../views'
import Products from '../views/Products.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      title: 'Главная',
    },
  },
  {
    path: '/products',
    component: Products,
    meta: {
      title: 'Продуктовый',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes
})

export default router
