<template>
  <div class="header color">
    <a href="https://miratorg.ru/products/catalog/napitki/?utm_source=afisha&utm_medium=specproekt&utm_campaign=MiratorgSummer24" target="_blank" class="header__logo header__logo-1" @click="sendEvent('logo_mirotorg')"></a>
    <a href="https://www.afisha.ru" target="_blank" class="header__logo header__logo-2" @click="sendEvent('logo_afisha')"></a>
  </div>
</template>

<script>
  export default {
	name: "Header",
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
</style>